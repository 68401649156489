<template>
<div class="view key-pad-scan">
	
</div>
</template>
<style>
</style>
<script>
export default {
	
}
</script>