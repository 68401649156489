<template>
    <div class="view manufacturers" data-aos="fade-in">
        <div class="container-fluid">
            <!-- Title -->
            <div class="title" data-aos="fade-in" v-if="liveGames">
                <router-link class="no-link" to="/platform/tables-lobby">
                    <div class="back"><i class="bi bi-chevron-left"></i>
                        Back</div>
                </router-link>
                <div class="name">
                    LIVE<br>
                    <b>{{ tableGroup.groupName.toUpperCase() }}</b>
                </div>
            </div>
            <div class="row games-single  justify-content-center" v-if="liveGames">
                <div class="col col-xl-3 col-lg-4 col-sm-6 col-12" v-for="item in liveGames" :key="item.id">
                    <img :src="getTableImage(item)" class="w-100" />
                    <div class="overlay" :class="{'disabled': !item.opened }" @click="createLiveGameSession(item)">


                        <div class="wins" v-if="item.lastWins">
                            <div class="win" 
                                v-for="win in item.lastWins.slice(0, 10)"
                                :class="getRouletteNumberColor(win)"
                                >
                                {{ win }}
                            </div>
                        </div>

                        <div class="dice" :class="{'top': !item.lastWins}">
                            <img src="@/assets/live/dice.png" alt="">
                        </div>

                        <div class="available" v-if="item.opened">Open</div>
                        <div class="available closed" v-if="!item.opened">Closed</div>
                        <div class="bets">
                            <div class="min-bet">
                                Min Bet<br>
                                ₺ {{ item.limitMin | moneyFormat(true) }}
                            </div>
                            <div class="max-bet">
                                Max Bet<br>
                                ₺ {{ item.limitMax | moneyFormat(true )}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "ManufacturersView",
    data() {
        return {
            data: null,
            sideSessions: null,
            user: null,
            showSideSessions: false,
            liveGames: null,
            tableGroup: null,
        }
    },
    async mounted() {
        if (process.env.VUE_APP_LOBBY_ENABLED == 'true') {
            this.getTableGroup();
            this.getLiveGames();
        }


    },
    methods: {
        getRouletteNumberColor(number) {

            if(number == 0) {
                return 'green';
            }

            if(number >= 1 && number <= 10 || number >= 19 && number <= 28)  {
                if(number % 2 == 0) {
                    return 'black';
                } else {
                    return 'red';
                }
            } else {
                if(number % 2 == 0) {
                    return 'red';
                } else {
                    return 'black';
                }
            }
        },
        getTableImage(table) {
            let tableStatus = (table.opened) ? 'online' : 'offline';
            return require('@/assets/live/' + table.gameId + '_' + tableStatus + '.png');
        },
        async getTableGroup() {
            let response = await this.$http.get('religa/games/groups');
            this.tableGroup = response.data.find(e => {
                return (e.groupId == this.$route.params.id);
            });
        },
        async createLiveGameSession(game) {
            try {
                let response = await axios.post('religa/sessions', game);
                let session = response.data;
                this.$router.push(`/live-games?userKey=${session.userKey}&tableId=${game.gameId}&gameType=${game.gameType}`)
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
            //:to="'/live-games?gameId=' + item.gameId" 
        },
        async getLiveGames() {
            try {
                let response = await this.$http.get('religa/games?groupId=' + this.$route.params.id);
                this.liveGames = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
    }
}
</script>