<template>
<div class="view main-lobby">
<div class="background-desktop" :style="'background-image: url(' + backgroundLandscape + ')'" v-if="backgroundLandscape"></div>
<div class="background-portrait" :style="'background-image: url(' + backgroundPortrait + ')'" v-if="backgroundPortrait"></div>
<div class="overlay">
	<div class="logo" v-if="logo"><img :src="logo" alt=""></div>
	<div class="images">
		<div class="image left" v-if="leftImage" data-aos="fade-right">
			<router-link to="/platform/manufacturers">
				<div class="picture">
					<img :src="leftImage" alt="">
				</div>
				<div class="details">
					<div class="subtitle">LIVE</div><br>
					<div class="title">SLOTS</div><br>
				</div>
			</router-link>
		</div>
		<div class="image right" v-if="rightImage" data-aos="fade-left">
			<router-link to="/platform/tables-lobby">
				<div class="picture">
					<img :src="rightImage" alt="">
				</div>
				<div class="details">
					<div class="subtitle">LIVE</div><br>
					<div class="title">TABLES</div>
				</div>
			</router-link>
		</div>	
	</div>
</div>
</div>
</template>
<script>
import { Buffer } from 'buffer';
export default {
	name: "MainLobbyView",
	data() {
		return {
			backgroundLandscape: null,
			backgroundPortrait: null,
			logo: null,
			leftImage: null,
			rightImage: null,
		}
	},
	async mounted() {

		if(!process.env.VUE_APP_MAIN_LOBBY_ENABLED) {
			this.$router.push('/platform/manufacturers')
		}

		this.downloadImage('SYS_MAIN_LOBBY_BACKGROUND', 'backgroundLandscape');
		this.downloadImage('SYS_MAIN_LOBBY_BACKGROUND_MOBILE_PORTRAIT', 'backgroundPortrait');
		this.downloadImage('SYS_MAIN_LOBBY_SLOTS_ICON', 'leftImage');
		this.downloadImage('SYS_MAIN_LOBBY_TABLES_ICON', 'rightImage');
		this.downloadImage('SYS_LOGIN_LOGO', 'logo');
	},
	methods: {
		async downloadImage(serverKey, localKey) {
			try {
				let extension = (['SYS_MAIN_LOBBY_BACKGROUND', 'SYS_MAIN_LOBBY_BACKGROUND_MOBILE_PORTRAIT'].includes(serverKey)) ? 'jpg' : 'png';
				this[localKey] = process.env.VUE_APP_API_REVOLUTION + `/CasinoPlayer/${serverKey}.${extension}`;
				/*
                let response = await this.$http({
                    method: 'get',
                    url: `/unigens/download_picture_by_key?path=CASINO_PLAYER&key=${serverKey}`,
                    responseType: 'arraybuffer',
                });

                let mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this[localKey] = 'data:' + mimeType + ';base64,' + imgBase64;*/
            } catch (error) {
                console.log(error);
            }
		}
	}
}
</script>