const filter = {
	name: 'moneyFormat',
	execute: (value, showZero) => {		
		if(value){
			if(value == 'NaN') {
				value = "0.00";
			}
			let dotSplit = value.toString().split('.');
			if(dotSplit.length > 1) {
				// Has .
				let fullNumber = dotSplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				return fullNumber + '.' + dotSplit[1];
			} else {
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");	
			}
		}
		else {
			return (showZero) ? 0 : '';
		}				
	}
}
export default filter;