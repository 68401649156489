<template>
<div class="view pair-tv">
	<div class="text-center" style="margin-top: 100px; font-size: 24px;">
		Connecting to TV...
	</div>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	name: "PairTV",
	data() {
		return {
			user: null,
		}
	},
	sockets: {
		tvbox_paired: function(tv_box) {
            console.log('tvbox paired', tv_box);
            this.setPair(tv_box);

            this.$router.push('/');
        },
	},
	async mounted() {
		console.log('pair tv', this.$route.params.id);
		await this.getUser();
		this.$socket.emit('pair_tvbox', this.$route.params.id, this.user);
	},
	computed: mapGetters(['getPair']),
	methods: {
		...mapActions(['setPair']),
		async getUser() {
            try {
                let response = await this.$http.get('application/userDetails');
                this.user = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },  
	}
}
</script>