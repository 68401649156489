<template>
<div class="code"> 
    To cast the video streams to your TV please insert the following code in your application:
    <div class="box">
    {{ videoCode }}
   	<div class="overlay codeBoxOverlay"></div>
    </div>
    <!-- <center>
        <small>This code will expire in {{ codeRemainingTime }} seconds</small>
    </center> -->
</div>
</template>
<style scoped>
.code .box {
    text-align: center;
    font-size: 32px;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    background-color: #6689B2;
    color: #fff;
    font-weight: 600;
    letter-spacing: 5px;
    position: relative;
}
.code .box .overlay { 
	top: 0;
	left: 0;
	position: absolute;
	background-color: #fff;
	width: 100%;
	height: 100%;
	opacity: 0.20;
	pointer-events: none !important;
}


</style>
<script>
import $ from 'jquery';
export default {
	data() {
		return {
			videoCode: null,
            codeRefreshInterval: null,
            codeRefreshTimeout: 20000,
            codeRefreshTimestamp: null,
            codeRemainingTime: 20,
            renderFrameInterval: null,
		}
	},
	mounted() {

		
		this.codeRefreshInterval = setInterval(function(){
			this.getVideoCode();
		}.bind(this), this.codeRefreshTimeout);

		this.renderFrameInterval = setInterval(function(){
			this.renderFrame();
		}.bind(this), 1000);

		this.$nextTick(() => {
			this.getVideoCode();	
		});
		


	},
	beforeDestroy() {
		clearInterval(this.codeRefreshInterval);
		clearInterval(this.renderFrameInterval);
	},
	methods: {

		renderFrame() {

			let diffMs = this.codeRefreshTimeout - this.$moment().diff(this.codeRefreshTimestamp) - 2000;

			this.codeRemainingTime = Math.round(diffMs / 1000);

		},

		getVideoCode() {


	        this.$http
	        	.get(process.env.VUE_APP_API_SIGNAL + '/api/sessions/' + this.$route.params.id + '/getStreamCode')
	        	.then(res => {
	        		this.videoCode = res.data.code;
	            	this.codeRefreshTimestamp = this.$moment(res.data.refreshed); 

				    $('.codeBoxOverlay').animate({'width': '100%'}, 0);
					$('.codeBoxOverlay').animate({
						'width': '0%',
					}, this.codeRefreshTimeout);

	        	})
	        	.catch(err => {
	        		console.log(err);
	        	});

            
        },
	}
}
</script>