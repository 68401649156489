<template>
<div class="qr-code">


	<div class="qr-holder">
	    <canvas id="qr"></canvas>
	</div>
</div>
</template>
<style scoped>
.qr-holder {
	text-align: center;
	padding: 50px;
}
#qr {
    display: inline-block;

}
</style>
<script>
import QRious from 'qrious';
export default {
	props: ['session_id'],
	data() {
		return {
			player: null,
		}
	},
	async mounted() {
		
		let token = JSON.parse(localStorage.getItem('token')).access_token;
		console.log('jwt', token);
		token = this.parseJwt(token);
		console.log('jwtdecoded', token.email);

		//await this.getPlayerDetails();

		let qrValue = {
			playerEmail: token.email,
			sessionId: this.session_id,
		}

        var qr = new QRious({
          element: document.getElementById('qr'),
          value: JSON.stringify(qrValue),
          size: 200,
        });

	},
	methods: {

		parseJwt(token) {
		  var base64Url = token.split('.')[1];
	    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
	        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	    }).join(''));

	    return JSON.parse(jsonPayload);
		},
		async getPlayerDetails() {

			try {
				let res = await this.$http.get('players/userInfo');
				this.player = res.data;
			} catch (err) {
				console.log(err);
			}

		}

	}
}
</script>