<template>
    <div class="view manufacturers" data-aos="fade-in">
        <div class="container-fluid">
            <!-- Title -->
            <div class="title">
                <router-link class="no-link" to="/platform/">
                    <div class="back"><i class="bi bi-chevron-left"></i>
                        Lobby</div>
                </router-link>
                <div class="name">
                    LIVE<br><b>TABLES</b>
                </div>
            </div>
            <div class="row games-category  justify-content-center">
                <div class="col  col-xl-3 col-lg-3 col-sm-6 col-12" v-for="item in liveGames" :key="item.id">
                    <router-link class="no-link" :to="'/platform/tables-lobby/' + item.groupId">
                        <img :src="getCategoryImage(item)" class="w-100">
                        <div class="overlay">
                            <div class="available">Available</div>
                            <div class="group-name">{{ item.groupName }}</div>
                            <div class="count">
                                {{ item.totaAvailableTables }}
                            </div>
                            <div class="total">Total Tables: {{ item.totalTables }}</div>
                            <div class="bets">
                                <div class="min-bet">
                                    Min Bet<br>
                                    ₺ {{ item.minBet | moneyFormat(true) }}
                                </div>
                                <div class="max-bet">
                                    Max Bet<br>
                                    ₺ {{ item.maxBet | moneyFormat(true )}}
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>


            </div>
            <!--
            <div class="row manufacturers extended justify-content-center">
                <div class="col-xxl-4 col-xl-3 col-lg-4 col-sm-4 col-12" v-for="item in liveGames" :key="item.id" >
                    <router-link class="no-link" :to="'/platform/tables-lobby/' + item.groupId">
                        <div class="manufacturer" :style="[{ border: '3px solid' + item.color }]">

                            <div class="background" v-if="item.BackgroundImage">
                                <auth-img  :filename="item.BackgroundImage.fileName" :src="getSlotImage(item.BackgroundImage.fileName)" alt="" style="width: 100%; height: 100%;" /> 
                            </div>
                            <div class="gradient" v-if="item.color" :style="`background: transparent linear-gradient(180deg, #FFFFFF00 0%, ${item.color} 100%) 0% 0% no-repeat padding-box;`"></div>
                            <div class="available-icon">Available</div>
                            <div class="top">
                                <div class="manufacturer-logo" v-if="item.ManufacturerImage">
                                    <auth-img  :filename="item.ManufacturerImage.fileName" :src="getSlotImage(item.ManufacturerImage.fileName)" alt="" style="width: 100%; height: 50px;" />
                                </div>
                                <div class="group-logo" v-if="item.Image">
                                    <auth-img  :filename="item.Image.fileName" :src="getSlotImage(item.Image.fileName)" alt="" style="width: 100%; height: 100px;" />
                                </div>
                                <div class="name" v-if="!item.Image">
                                    {{ item.groupName }}
                                </div>
                                
                            </div>

                            <div class="overlay">
                                {{ item.totaAvailableTables }}<br>
                            </div>
                            <div class="small">Total Tables {{ item.totalTables }}</div>
                            <div class="total" >
                                <div class="text">
                                    <div class="left">
                                        <b>Min Bet</b><br>
                                        {{ item.minBet | moneyFormat }}
                                    </div>
                                    <div class="right">
                                        <b>Max Bet</b><br>
                                        {{ item.maxBet | moneyFormat }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            -->
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "ManufacturersView",
    data() {
        return {
            data: null,
            sideSessions: null,
            user: null,
            showSideSessions: false,
            liveGames: null,
        }
    },
    async mounted() {

        this.getLiveGames();


    },
    methods: {

        getCategoryImage(category) {
            console.log(category);
            let filename = category.groupName.toLowerCase() + '.png';
            return require('@/assets/live/' + filename);

        },
        getSlotImage(filename) {
            return process.env.VUE_APP_API_LOCALSERVER + '/api/v1/unigens/download_picture?fileName=' + filename
        },
        async createLiveGameSession(game) {
            try {
                let response = await axios.post('religa/sessions', game);
                let session = response.data;
                this.$router.push(`/live-games?userKey=${session.userKey}&tableId=${game.gameId}&gameType=${game.gameType}`)
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
            //:to="'/live-games?gameId=' + item.gameId" 
        },
        async getLiveGames() {
            try {
                let response = await this.$http.get('religa/games/groups');
                this.liveGames = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
    }
}
</script>