<template>
    <video :id="'video_' + code" ref="video" class="videoStream" :class="addclass" playsinline autoplay :muted="!soundEnabled"></video>
</template>
<script>
import $ from 'jquery';
export default {
    props: ['code', 'addclass', 'touchEnabled', 'rotation', 'layer', 'soundEnabled', 'videoStream'],
    data() {
        return {
            debug: false,
            force_turn: false,
            video_div: null,
            // Socket.io
            socket: null,
            socket_address: '',
            socket_query: {
                client_type: 'watcher',
            },
            // Control Boards
            smib_id: 1,
            axios_config: {
                headers: {
                    AuthKey: 'a6abc6c14df6cf31e09c2728549529a745a47436',
                    ClientId: 'CASINO2.0',
                    'Content-Type': 'application/json'
                }
            },
            stats: {},
            // Streams
            selected_broadcaster: 'null',
            broadcasters: [],
            // WebRTC Broadcaster Connection
            peer_connection_state: 'disconnected',
            peer_connection_stats: {
                video: [],
                audio: [],
            },
            peer_connection: null,
            // WebRTC Configuration
            webrtc_config: {
                iceServers: [

                    //{ urls: 'stun:stun.l.google.com:19302', },
                    //{ urls: 'stun:stun1.l.google.com:19302' },
                    //{ urls: 'stun:stun2.l.google.com:19302' },
                    //{ urls: 'stun:stun3.l.google.com:19302' },
                    //{ urls: 'stun:stun4.l.google.com:19302' },

                    { urls: 'stun:stun.cloud.ro:5349' },
                    {
                        urls: 'turn:turn.cloud.ro:5349',
                        username: 'turnuser',
                        credential: 'letmein123',
                    }

                    // { 
                    //   "urls": "turn:TURN_IP?transport=tcp",
                    //   "username": "TURN_USERNAME",
                    //   "credential": "TURN_CREDENTIALS"
                    // }
                ]
            }
        }
    },
    mounted() {

        var self = this;

        self.video_div = document.getElementById("video_" + this.code);

        // Initiate web rtc connection
        self.socket = this.$socket;

        self.getBroadcasters();
        self.initTouch();

        // Div rotated, calculate boundries manually 
        if (this.rotation > 0) {
            let $video = $("#video_" + this.code);
            let $parent = $video.parent();
            console.log('ph', $parent.height());
            $video.css('width', $parent.height() + 'px');
            $video.css('transform', `rotate(${this.rotation}deg)`);
        } else {
            let $video = $("#video_" + this.code);
            $video.css('transform', `rotate(0deg)`);
            let $parent = $video.parent();
            // Calulate dynamic video size based on
            // aspect ratio  
            let videoAR = this.videoStream.videoStreamAspectRatio.width / this.videoStream.videoStreamAspectRatio.height;
            let containerAR = $parent.width() / $parent.height();
            let containerOrientation = (videoAR <= containerAR) ? 'landscape' : 'portrait';
            if (containerOrientation == 'landscape') {
                // Fit video by max height
                let videoHeight = $parent.height();
                let videoWidth = videoHeight * this.videoStream.videoStreamAspectRatio.width / this.videoStream.videoStreamAspectRatio.height;
                $video.css('height', videoHeight + 'px');
                $video.css('width', videoWidth + 'px');
            } else {
                let videoWidth = $parent.width();
                let videoHeight = videoWidth * this.videoStream.videoStreamAspectRatio.height / this.videoStream.videoStreamAspectRatio.width;
                $video.css('height', videoHeight + 'px');
                $video.css('width', videoWidth + 'px');
            }

        }






    },

    destroyed() {

        console.log('desrtoyed');
        this.closeConnection(this.selected_broadcaster);

    },

    sockets: {

        offer: function(data) {

            let broadcaster_socket_id = data[0];
            let description = data[1];

            if (broadcaster_socket_id !== this.selected_broadcaster) {
                return;
            }

            this.answerOffer(broadcaster_socket_id, description);

        },
        candidate: function(data) {

            let broadcaster_socket_id = data[0];
            let candidate = data[1];

            if (broadcaster_socket_id !== this.selected_broadcaster) {
                return;
            }


            if (this.force_turn) {
                var stringCandidate = candidate.candidate;
                if (stringCandidate.indexOf("relay") < 0) {
                    return;
                }
            }

            this.peer_connection.addIceCandidate(new RTCIceCandidate(candidate));

        },
        update_broadcasters: function(data) {

            let state = data[0];
            let broadcaster_socket_id = data[1];

            if (state === 'disconnected') {
                if (this.selected_broadcaster === broadcaster_socket_id) {

                    this.closeConnection();
                    this.selected_broadcaster = 'null';

                }
            }

            this.getBroadcasters();


        }

    },
    watch: {
        'layer': {
            deep: true,
            handler() {
                if (this.rotation > 0) {
                    let $video = $("#video_" + this.code);
                    if (!$video) {
                        console.log('no vide in layout handler?');
                        return;
                    }
                    let $parent = $video.parent();
                    console.log('ph', $parent.height());
                    $video.css('width', $parent.height() + 'px');
                    $video.css('transform', `rotate(${this.rotation}deg)`);
                } else {
                    let $video = $("#video_" + this.code);
                    $video.css('transform', `rotate(0deg)`);
                    let $parent = $video.parent();
                    // Calulate dynamic video size based on
                    // aspect ratio  
                    let videoAR = this.videoStream.videoStreamAspectRatio.width / this.videoStream.videoStreamAspectRatio.height;
                    let containerAR = $parent.width() / $parent.height();
                    let containerOrientation = (videoAR <= containerAR) ? 'landscape' : 'portrait';
                    if (containerOrientation == 'landscape') {
                        // Fit video by max height
                        let videoHeight = $parent.height();
                        let videoWidth = videoHeight * this.videoStream.videoStreamAspectRatio.width / this.videoStream.videoStreamAspectRatio.height;
                        $video.css('height', videoHeight + 'px');
                        $video.css('width', videoWidth + 'px');
                    } else {
                        let videoWidth = $parent.width();
                        let videoHeight = videoWidth * this.videoStream.videoStreamAspectRatio.height / this.videoStream.videoStreamAspectRatio.width;
                        $video.css('height', videoHeight + 'px');
                        $video.css('width', videoWidth + 'px');
                    }

                }

            }
        },
        force_turn: function(oldValue, newValue) {

            this.closeConnection(this.selected_broadcaster);
            this.reconnect();

        },

        // On stream selection change
        selected_broadcaster: function(newStream, oldStream) {

            // Close any existing peer connection
            this.closeConnection(oldStream);

            // Return if selected none
            if (this.selected_broadcaster === 'null') {
                this.$forceUpdate();
                return;
            }

            // Broadcast the intetion to watch to the
            // specific stream
            this.socket.emit('watch', this.selected_broadcaster);
            this.$forceUpdate();

        }

    },

    methods: {
        initTouch() {

            if (!this.touchEnabled) {
                return;
            }

            let videoDiv = $('#video_' + this.code);

            videoDiv.on('mousedown', (e) => {
                this.onTouchStart(e, videoDiv);
            });


        },

        onTouchStart(event, video) {

            console.log('rotation', this.rotation);

            let videoWidth = video.width();
            let videoHeight = video.height();

            if(this.rotation != 0) {
                videoWidth = video.height();
                videoHeight = video.width();
            }

            let clickX_PX = event.pageX - video.offset().left;
            let clickY_PX = event.pageY - video.offset().top;

            let clickX_PCT = parseFloat(clickX_PX / videoWidth * 100).toFixed(2);
            let clickY_PCT = parseFloat(clickY_PX / videoHeight * 100).toFixed(2);

            console.log('x', clickX_PCT);

            this.$emit('touchStart', {
                x: clickX_PCT,
                y: clickY_PCT,
            });

        },
        addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        sendCommand(command) {
            /*data = {
              button_key: command,
            }*/
            var broadcaster = this.broadcasters.find(item => item.id === this.selected_broadcaster);
            var smib_id = broadcaster.query.smib_id;
            var data = new FormData();
            data.append('button_key', command);

            this.$http.post('https://old.cloud.ro/api/1.0/unigens/ed_ioboard_set.php?smib_id=' + smib_id, data, this.axios_config)
                .then((res) => {
                    console.log(res.data);
                })
                .catch((error) => {
                    console.log(error);
                });

            console.log(this.smib_id);
            console.log('Command issued: ' + command);
        },
        closeConnection(closedBroadcasterId) {
            if (this.peer_connection) {

                this.socket.emit('stop_watch', closedBroadcasterId);

                this.peer_connection.close();
                this.peer_connection = null;
                this.peer_connection_state = 'disconnected';
                this.video_div.srcObject = null;
                this.$forceUpdate();
            }
        },
        reconnect() {
            if (this.selected_broadcaster) {
                if (this.selected_broadcaster !== 'null') {
                    console.log('Connection lost, attempting to reconnect...');
                    this.socket.emit('watch', this.selected_broadcaster);
                }
            }
        },
        answerOffer: function(broadcaster_socket_id, description) {
            var self = this;
            // Initiate a new WebRTC Peer Connection based on our 
            // config file and the description sent from the broadcaster
            self.peer_connection = new RTCPeerConnection(this.webrtc_config);
            self.peer_connection
                .setRemoteDescription(description)
                .then(() => self.peer_connection.createAnswer())
                .then(sdp => self.peer_connection.setLocalDescription(sdp))
                .then(() => {
                    self.socket.emit("answer", broadcaster_socket_id, self.peer_connection.localDescription);
                });

            // On receive track from broadcaster
            self.peer_connection.ontrack = event => {
                self.video_div.srcObject = event.streams[0];
                self.$forceUpdate();
            };

            // Emit our watcher candidates to 
            // the broadcaster
            self.peer_connection.onicecandidate = event => {
                if (event.candidate) {


                    if (self.force_turn) {
                        // Only accept turn connections
                        var candidate = event.candidate.candidate;
                        if (candidate.indexOf("relay") < 0) {
                            return;
                        }
                    }


                    self.socket.emit("candidate", broadcaster_socket_id, event.candidate);
                }
            };

            self.peer_connection.onconnectionstatechange = function() {

                self.peer_connection_state = self.peer_connection.connectionState;
                self.$forceUpdate

                // Handle peer disconnect
                if (self.peer_connection_state === 'disconnected') {
                    self.reconnect();
                }

                console.log('state changed to:' + self.peer_connection.connectionState);
            }




        },


        getBroadcasters: function() {

            var self = this;

            this.$http.get(process.env.VUE_APP_API_SIGNAL + '/api/streams')
                .then(function(res) {

                    self.broadcasters = res.data;

                    let broadcaster = self.broadcasters.find(item => item.query.stream_key === self.code);

                    if (broadcaster) {
                        self.selected_broadcaster = broadcaster.id;
                    }

                    //console.log(self.broadcasters);


                })
                .catch(function(error) {
                    console.log(error);
                });
        },


        handleError: function(error) {
            console.log(error);
        }

    }
}
</script>