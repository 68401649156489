<template>
    <div class="component play-header" style="z-index: 99; position: relative;">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="padding: 0px 20px;">
            <a class="navbar-brand">
                <!-- <div class="details" v-if="details">
                    <div class="producer">{{ details.manufacturer }}</div>
                    <div class="slot">{{ details.slotNumber }}</div>
                </div> -->
            </a>
            
            <!-- <img class="logo" src="@/assets/images/logo.png" height="60%" alt="" style="position: absolute; left: 47%;"> -->
            <div class="machine">
                {{ machine.manufacturer.name }} {{ machine.model.name }} #{{ machine.number }}
            </div>
            
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <div class="form-inline my-2 my-lg-0 mr-auto">
                    <div class="balances" v-if="balances">
                        <div class="balance" v-for="summary in balances" v-if="summary.moneyType.shortName != 'PM'">
                            <div class="value">
                                    <div class="code">
                                        {{ summary.currency.code }}
                                    </div>
                                    <div class="money">
                                        {{ summary.value | moneyFormat(true) }}   
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="back" @click="clickBack" style="position: absolute; right: 20px; top: calc(50% - 1.5vh); font-size: 1.7vh; cursor: pointer;">
                    Close
                </div>
                <!-- <ul class="navbar-nav " style="cursor: pointer; position: absolute; z-index: 999; right: 10px;">
                    <li @click="clickBack" class="nav-item active" style="cursor: pointer;">
                        
                    </li>
                </ul> -->
            </div>
        </nav>
    </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
    props: ['machine'],
    computed: mapGetters(['loggedIn']),
    data() {
        return {
            user: null,
            balances: null,
            interval: null,
            details: null,
        }
    },
    async mounted() {
        await this.getUser();
        await this.getBalance();
        var self = this;
        self.interval = setInterval(function() {
            self.getBalance();
        }, 5000);

        this.$root.$on('setHeaderDetails', (details) => {
            console.log('rec event', details);
            this.details = details;
        });

    },
    destroyed() {
        clearInterval(this.interval);
    },
    sockets: {
        refreshBalance: function() {
            this.getBalance();
        },
    },
    methods: {
        async checkTransactionStatus() {
            let response = await axios.get(`unigens/can_make_cashless_transactions?distributed_session_id=${this.$route.params.id}`);
            return response.data.Result;
        },
        async clickBack() {
            let check = await this.checkTransactionStatus();
            if(check) {
                this.$router.push('/platform');
            } else {
                this.$toasted.error("Please take win before attempting this action.");
            }
        },
        async getUser() {
            try {
                let response = await this.$http.get('application/userDetails');
                this.user = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        async getBalance() {
            try {
                let response = await this.$http.get('players/details');
                this.balances = response.data.account.summaries;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
    }
}
</script>