<template>
    <div class="view layout-sim">
        <div class="loading-screen funds-transfer" v-if="session && (session.machine.play_status == 'CASHLESS_IN_TRANSFERING' || session.machine.play_status == 'CASHLESS_OUT_AND_CLOSE_SESSION_STARTING')">
            <div class="box">
                <div class="spinner">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div class="title">Please wait!</div>
                <div class="subtitle">Your funds are being transferred.</div>
                <div class="egm">EGM: #<b>{{ session.machine.number }}</b></div>
                <div class="session">SESSION: {{ session.distributed_id }}</div>
            </div>
        </div>
        <div class="loading-screen funds-transfer" v-if="session && session.machine.play_status == 'MAINTENANCE'">
            <div class="box">
                <div class="spinner">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div class="title">Please wait!</div>
                <div class="subtitle">The machine is in maintenance mode.</div>
                <div class="egm">EGM: #<b>{{ session.machine.number }}</b></div>
                <div class="session">SESSION: {{ session.distributed_id }}</div>
            </div>
        </div>
        <div class="loading-screen funds-transfer" v-if="session && session.machine.play_status == 'LOCKED'">
            <div class="box">
                <div class="spinner">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div class="title">Please wait!</div>
                <div class="subtitle">The machine has been locked.</div>
                <div class="egm">EGM: #<b>{{ session.machine.number }}</b></div>
                <div class="session">SESSION: {{ session.distributed_id }}</div>
            </div>
        </div>
        <div class="loading-screen funds-transfer" v-if="session && session.machine.signalStatus == 'offline'">
            <div class="box">
                <div class="spinner">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div class="title">Please wait!</div>
                <div class="subtitle">Attempting to reconnect...</div>
                <div class="egm">EGM: #<b>{{ session.machine.number }}</b></div>
                <div class="session">SESSION: {{ session.distributed_id }}</div>
            </div>
        </div>
        <div class="canvas" v-if="layout">
            <!-- Image Button Custom Style -->
            <!-- Custom Elements -->
            <div @click="toggleSideplayPlaying" v-for="object in layout" class="object Button BTN_TEXT BTN_GREEN" :class="{'BTN_GREEN': sideSession.Status == 'PAUSED', 'BTN_RED': sideSession.Status == 'RUNNING'}" v-if="object.ObjType == 'ToggleSideplayPlaying' && sideSession" v-show="getVisibility(object)" :style="getStyle(object)">
                <div class="front">
                    <div class="text">
                        {{ sideSession.Status == 'RUNNING' ? 'PAUSE' : 'PLAY' }}
                    </div>
                </div>
            </div>
            <!-- MetalDown -->
            <div @click="toggleSoundEnabled" v-for="object in layout" class="object Button BTN_BG BTN_BG_ICON BTN_BG_SHADOW_1" v-if="object.ObjType == 'ButtonTAudio' && $route.query.designStyle == 'MetalDown'" v-show="getVisibility(object)" :style="getStyle(object)">
                <div class="front">
                    <div class="icon" style="padding-top: 30%;">
                        <img v-if="soundEnabled" :src="'/images/buttons/Icon_Sound.png'" alt="" width="40%">
                        <img v-if="!soundEnabled" :src="'/images/buttons/Icon_Sound_Mute.png'" alt="" width="40%">
                    </div>
                </div>
            </div>
            <div @click="toggleVideoLayout" v-for="object in layout" class="object Button BTN_BG BTN_BG_ICON BTN_BG_SHADOW_1" v-if="object.ObjType == 'ButtonTVideo' && $route.query.designStyle == 'MetalDown'" v-show="getVisibility(object)" :style="getStyle(object)">
                <div class="front">
                    <div class="icon" style="padding-top: 27%;">
                        <img v-if="videoLayoutKey == 'VL_ONE'" :src="'/images/buttons/Icon_Camera_1.png'" alt="" width="35%">
                        <img v-if="videoLayoutKey == 'VL_TWO'" :src="'/images/buttons/Icon_Camera_2.png'" alt="" width="35%">
                    </div>
                </div>
            </div>
            <!-- GrayMetal -->
            <div @click="toggleSoundEnabled" v-for="object in layout" class="object Button BTN_NEWTYPE" v-if="object.ObjType == 'ButtonTAudio' && $route.query.designStyle == 'GrayMetal'" v-show="getVisibility(object)" :style="getStyle(object)">
                <div class="front">
                    <div class="images">
                        <div class="on" :style="{ backgroundImage: 'url(/images/buttons/SavoyIcons/Icon_Sound_On.png)' }" v-if="soundEnabled">
                        </div>
                        <div class="on" :style="{ backgroundImage: 'url(/images/buttons/SavoyIcons/Icon_Sound_Mute_On.png)' }" v-if="!soundEnabled">
                        </div>
                    </div>
                </div>
            </div>
            <div @click="toggleVideoLayout" v-for="object in layout" class="object Button BTN_NEWTYPE" v-if="object.ObjType == 'ButtonTVideo' && $route.query.designStyle == 'GrayMetal'" v-show="getVisibility(object)" :style="getStyle(object)">
                <div class="front">
                    <div class="images">
                        <div class="on" :style="{ backgroundImage: 'url(/images/buttons/SavoyIcons/Icon_Camera_1.png)' }" v-if="videoLayoutKey == 'VL_ONE'">
                        </div>
                        <div class="on" :style="{ backgroundImage: 'url(/images/buttons/SavoyIcons/Icon_Camera_2.png)' }" v-if="videoLayoutKey == 'VL_TWO'">
                        </div>
                    </div>
                </div>
            </div>
            <!-- Default Elements -->
            <div @pointerdown="handleObjectMouseDown(object)" @pointerup="handleObjectMouseUp(object)" class="object" v-for="object in layout" :class="[object.ObjClass, object.ObjType]" v-show="getVisibility(object)" :style="getStyle(object)" v-if="!customElements.includes(object.ObjType)">
                <div class="front">
                    <!-- Header -->
                    <header-play :machine="machine" v-if="object.ObjType == 'Header'" />
                    <!-- VideoCanvas -->
                    <div v-if="object.ObjType == 'VideoCanvas'">
                        <div class="videoLayer" v-for="(layer, index) in videoLayout" :style="{
                            'top': layer.VStreamTop + '%',
                            'left': layer.VStreamLeft + '%',
                            'width': layer.VStreamWidth + '%',
                            'height': layer.VStreamHeight + '%',
                        }">
                            <!-- <div class="text">
                                {{ layer.VStreamTypeKey }}
                            </div> -->
                            <view-stream :soundEnabled="soundEnabled" :layer="layer" :rotation="layer.VStreamRotation" v-if="machine.videoStreams.find(e => e.videoStreamType.code == layer.VStreamTypeKey)" :videoStream="machine.videoStreams.find(e => e.videoStreamType.code == layer.VStreamTypeKey)" @touchStart="touchStart" :touchEnabled="machine.videoStreams.find(e => e.videoStreamType.code == layer.VStreamTypeKey).videoStreamType.code == 'Game'" addclass="video_1" :code="machine.videoStreams.find(e => e.videoStreamType.code == layer.VStreamTypeKey).code" class="video" :class="{'rotated': layer.VStreamRotation > 0 }" />
                            <!-- {{ machine.videoStreams.find(e => e.videoStreamType.code == layer.VStreamTypeKey).code }} -->
                        </div>
                    </div>
                    <!-- Button Text or Icon -->
                    <div class="images" v-if="object.ObjClass.includes('BTN_NEWTYPE') || object.ObjClass.includes('BTN_BG BTN_BG_TEXT')">
                        <div class="on" :style="{ backgroundImage: 'url(/images/buttons/' + object.ObjTextRow1 + ')' }">
                        </div>
                        <div class="off" :style="{ backgroundImage: 'url(/images/buttons/' + object.ObjTextRow2 + ')' }">
                        </div>
                    </div>
                    <!-- Button Text or Icon -->
                    <div class="text" v-if="object.ObjClass.includes('BTN_TEXT') || object.ObjClass.includes('BTN_BG BTN_BG_TEXT')">
                        {{ object.ObjTextRow1 }}<br>
                        {{ object.ObjTextRow2 }}
                    </div>
                    <div class="icon" v-if="object.ObjClass.includes('BTN_BG_ICON')">
                        <img :src="'/images/buttons/' + object.ObjTextRow1" alt="" width="100%">
                    </div>
                    <div class="icon" v-if="object.ObjClass.includes('BTN_ICON')">
                        <img :src="getIcon(object.ObjTextRow1)" alt="">
                    </div>
                    <!-- Text Text -->
                    <div class="text" v-if="object.ObjType == 'Text'">
                        <div class="static" v-if="object.ObjClass.includes('STATIC')">
                            {{ object.ObjTextRow1 }}<br>
                            {{ object.ObjTextRow2 }}
                        </div>
                        <div class="value" v-if="object.ObjClass.includes('VALUE')">
                            {{ replaceInfo(object.ObjTextRow1, object) }}
                        </div>
                    </div>
                    <!-- Table: Player -->
                    <div class="table player" v-if="object.ObjType == 'Table' && object.ObjClass.includes('PLAYER')">
                        <table class="w-100 h-100">
                            <thead>
                                <tr>
                                    <td class="time">
                                        Time
                                    </td>
                                    <td class="bet">
                                        Bet
                                    </td>
                                    <td class="won">
                                        Won
                                    </td>
                                    <td class="topwon">
                                        Top Won
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in sideSession.Info.SideTracking">
                                    <td class="time">{{ item.Timestamp | moment("HH:mm:ss") }}</td>
                                    <td class="bet">{{ item.Bet.toFixed(4) }}</td>
                                    <td class="won">{{ item.Won.toFixed(4) }}</td>
                                    <td class="topwon">{{ item.TopWon.toFixed(4) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Table: Show -->
                    <div class="table player" v-if="object.ObjType == 'Table' && object.ObjClass.includes('RANK')">
                        <table class="w-100 h-100">
                            <thead>
                                <tr>
                                    <td class="time">
                                        Rank
                                    </td>
                                    <td class="text-left">
                                        Player
                                    </td>
                                    <td class="topwon">
                                        Value
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in sideSession.SideSession.RankRows" :class="{'me': item.IsCurrentUser }">
                                    <td class="time">#{{ item.Rank }}</td>
                                    <td class="text-left">{{ item.PlayerUserName }}</td>
                                    <td class="topwon">{{ item.RankValue.toFixed(2) | moneyFormat }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Table: Rank -->
                    <div class="table player" v-if="object.ObjType == 'Table' && object.ObjClass.includes('SHOW')">
                        <table class="w-100 h-100">
                            <thead>
                                <tr>
                                    <td class="time">
                                        Time
                                    </td>
                                    <td class="bet">
                                        Bet
                                    </td>
                                    <td class="won">
                                        Won
                                    </td>
                                    <td class="topwon">
                                        Top Won
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in sideSession.Info.MainPlayerTracking">
                                    <td class="time">{{ item.Timestamp | moment("HH:mm:ss") }}</td>
                                    <td class="bet">{{ item.Bet.toFixed(4) }}</td>
                                    <td class="won">{{ item.Won.toFixed(4) }}</td>
                                    <td class="topwon">{{ item.TopWon.toFixed(4) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlays">
            <div class="row loadMoney overlay  justify-content-md-center align-content-center" v-if="handpay" data-aos="fade-in" style="height: calc(100vh + 50px)">
                <div class="box col-xs-4" data-aos="fade-down">
                    <div class="head">
                        <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                        <div class="text text-center">
                            Handpay is pending
                        </div>
                    </div>
                    <div class="row chips justify-content-md-center">
                        <div class="col" style="font-size: 56px; color: #4fb83a; font-weight: bold;">
                            {{ handpay.toFixed(2) | moneyFormat(true) }}
                        </div>
                    </div>
                    <div class="row cancel">
                        <div class="col">
                            <button @click="cashHandpay" type="button" class="btn btn-secondary form-control">TRANSFER HANDPAY TO WALLET</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row loadMoney overlay  justify-content-md-center align-content-center" v-if="showCastKeys" data-aos="fade-in" style="height: calc(100vh + 50px)">
                <div class="box col-xs-2" data-aos="fade-down">
                    <div class="head">
                        <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                        <div class="text text-center">
                            CAST KEYS TO PHONE
                        </div>
                    </div>
                    <div class="row chips justify-content-md-center">
                        <div class="col">
                            Scan the qr code to continue
                            <qr-code :session_id="$route.params.id" />
                        </div>
                    </div>
                    <div class="row cancel">
                        <div class="col">
                            <button @click="showCastKeys = false" type="button" class="btn btn-secondary form-control">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row loadMoney overlay  justify-content-sm-center align-content-center" v-if="showInsert" data-aos="fade-in" style="height: calc(100vh + 50px)">
                <div class="box col-xs-2" data-aos="fade-down">
                    <div class="head">
                        <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                        <div class="text">
                            CURRENT AMOUNT <div class="pull-right">{{ balance | moneyFormat(true) }} {{ balanceCurrency }}</div>
                        </div>
                    </div>
                    <div class="row chips justify-content-sm-center">
                        <div class="col-xs-2" v-for="money in machine.predefinedMoneyInValues">
                            <div class="chip" @click="insert(money.MoneyValue)">
                                <div class="image">
                                    <img :src="'/images/chips/' + money.MoneyImage" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount" :class="{'allin': money.MoneyValue == 'ALL_IN'}">
                                        {{ money.MoneyValue.replace("_", " ") | moneyFormat(true) }}
                                    </div>
                                    <div class="currency" v-if="money.MoneyValue != 'ALL_IN'">
                                        {{ balanceCurrency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row cancel">
                        <div class="col">
                            <button @click="showInsert = false" type="button" class="btn btn-secondary form-control">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="overlay gray-metal-insert" data-aos="fade-in" v-if="showInsert">
                <!-- Portrait box -->
                <div class="box" :style="'transform: scale('+insertScale+');'" v-if="insertType == 'portrait'">
                    <div class="head">
                        <div class="label">CURRENT AMOUNT</div>
                        <div class="value">{{ balance | moneyFormat(true) }} {{ balanceCurrency }}</div>
                    </div>
                    <div class="chips">
                        <div class="chip" @click="insert(money.MoneyValue)" v-for="money in  machine.predefinedMoneyInValues.find(e => e.design_style == 'GreyMetal').values">
                            <img class="image" :src="'/images/buttons/SavoyIcons/' + money.MoneyImage" alt="">
                        </div>
                    </div>
                    <div @click="showInsert = false" class="cancel">
                        CANCEL
                    </div>
                </div>
                <!-- Landscape Box -->
                <div class="box-landscape" :style="'transform: scale('+insertScale+');'" v-if="insertType == 'landscape'">
                    <div class="chips">
                        <div class="chip" @click="insert(money.MoneyValue)" v-for="money in  machine.predefinedMoneyInValues.find(e => e.design_style == 'GreyMetal').values">
                            <img class="image" :src="'/images/buttons/SavoyIcons/' + money.MoneyImage" alt="">
                        </div>
                    </div>
                    <div class="right">
                        <div class="head">
                            <div class="label">CURRENT AMOUNT</div>
                            <div class="value">{{ balance | moneyFormat(true) }} {{ balanceCurrency }}</div>
                        </div>
                        <div @click="showInsert = false" class="cancel">
                            CANCEL
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "LayoutSimView.scss";
</style>
<script>
import axios from 'axios';
import ViewStream from '../Play/components/ViewStream.vue';
import moment from 'moment';
import QRCode from '@/components/Qrcode/Qrcode.vue';
import HeaderPlay from '@/components/Header/HeaderPlay.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            viewport: {
                width: null,
                height: null,
                mobile: false,
            },
            resizeBounce: false,
            layout: null,
            viewState: '1',
            longClickTime: 1000,
            showInsert: false,
            hand: 'Left',
            menuOrientation: 'Vertical',
            balance: 0,
            balanceCurrency: null,
            session: null,
            machine: null,
            loading: false,
            loading_status: '',
            keys_blocked: false,
            showCastKeys: false,
            lastOrientation: null,
            videoLayout: null,
            videoLayoutKey: 'VL_ONE',
            videoStreams: ['Game', 'Floor', 'Jackpot'],
            viewMode: 'Standard',
            ignoreUp: false,
            sideSession: null,
            tableScope: null,
            casting: false,
            customElements: ["ToggleSideplayPlaying", "ButtonTAudio", "ButtonTVideo"],
            handpay: null,
            soundEnabled: false,
            insertScale: 1,
            insertType: 'portrait',
            exitTimeout: null,
            exitTimeoutInterval: 10000,
            exitPending: false,
        }
    },
    computed: mapGetters(['getPair']),
    components: {
        HeaderPlay,
        ViewStream,
        'qr-code': QRCode,
    },
    sockets: {
        update_broadcasters: function(event) {
            console.log('update_broadcasters');
            this.getMachine();
        },
        handpayPending: function(event) {
            console.log('handpay pending', event);
            this.handpay = event;
        },
        refreshPlayerSideSessionsDetails: function() {
            console.log('refreshPlayerSideSessionsDetails');
            this.getSideSession();
        },
        cast_stop: function() {
            //this.$router.push('/');
        },
        cast_start: function() {
            console.log('cast starting');
            this.showCastKeys = false;
            this.$route.query.viewMode = 'CastView';
            this.initLayout();
        },
        closedSession: function(closed_distributed_id) {
            if (this.session.distributed_id == closed_distributed_id) {
                this.$router.push('/');
            } else {
                console.log('other session closed');
            }
        },
        startLoading: function(status) {
            console.log('startLoading');
            this.loading = true;
            this.loading_status = status;
        },
        stopLoading: function() {
            console.log('stopLoading');
            this.loading = false;
        },
        session_message: function(message) {
            console.log(message);
            if (message.session_id === this.session.id) {
                if (message.type === 'success') {
                    this.$toasted.success(message.message);
                } else if (message.type === 'error') {
                    this.$toasted.error(message.message);
                }
            }
        },
    },
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#In-Component-Guards
    async beforeDestroy() {

        this.$root.$off('keydown');

        console.log('query system', this.$route.query.system);

        window.removeEventListener("resize", this.resizeEventListener);

        clearTimeout(this.exitTimeout);

        /*window.removeEventListener("resize", (e) => {
            if (this.resizeBounce) {
                clearTimeout(this.resizeBounce);
            }
            this.resizeBounce = setTimeout(() => {
                this.initLayout();
            }, 500);
        });*/

        await this.closeSidebetSession();
        if (this.getPair) {
            this.$socket.emit('tvbox_stop_session', this.session.distributed_id, this.getPair.device_id);
        }
        // only trigger exit if not already pending
        if (!this.exitPending) {
            this.$socket.emit('pause_play', this.machine.id, this.session.distributed_id, this.$socket.id);
        }
        document.exitFullscreen();
    },
    async mounted() {

        // Space to spin
        this.$root.$on('keydown', (event) => {
            if (event.code === 'Space') {
                let startButton = this.machine.buttonCodes.find(e => {
                    return (e.action === 'START');
                });
                if (startButton) {
                    this.sendRibCommand(startButton.code);
                }
            }
        });

        /*try {
            await document.documentElement.requestFullscreen();
            await screen.orientation.lock('landscape');
        } catch (error) {
            console.log('orientation lock error');
        }*/


        /*if(localStorage.getItem('soundEnabled')) {
            console.log(localStorage.getItem('soundEnabled'));
            this.soundEnabled = (/true/).test(localStorage.getItem('soundEnabled'));
        }*/

        // Load local storage variables
        if (localStorage.getItem('hand')) {
            this.hand = localStorage.getItem('hand');
        }
        if (localStorage.getItem('menuOrientation')) {
            this.menuOrientation = localStorage.getItem('menuOrientation');
        }
        if (localStorage.getItem('videoLayoutKey')) {
            let localStorageKey = localStorage.getItem('videoLayoutKey');
            let allowedKeys = ['VL_ONE', 'VL_TWO'];
            if (allowedKeys.includes(localStorageKey)) {
                this.videoLayoutKey = localStorage.getItem('videoLayoutKey');
            } else {
                this.videoLayoutKey = 'VL_ONE';
                localStorage.setItem('videoLayoutKey', 'VL_ONE');
            }
        } else {
            localStorage.setItem('videoLayoutKey', 'VL_ONE');
        }


        // Update socket details for sidesession
        if (this.$route.query.system && this.$route.query.system == 'SidePlayer') {
            await this.getSideSession();
            // maybe update player socket
            if (this.$socket.id != this.sideSession.PlayerSocketId) {
                this.updateSideSessionPlayerSocketId(this.$socket.id);
            }
        }

        await this.getMachine();
        await this.initLayout();

        // Update player socket id on normal sessions
        if (!this.$route.query.system && this.$route.query.viewMode != 'keypad') {
            this.updatePlayerSocketId(this.$socket.id);
        }

        // Add resize event with bounce
        window.addEventListener("resize", this.resizeEventListener);

        // Emit keypad events
        if (this.$route.query.viewMode == 'keypad') {
            console.log('cast_start', this.$route.params.id);
            this.$socket.emit('cast_start', this.$route.params.id);
        }


        this.$socket.emit('start_play', this.machine.id, this.session.distributed_id, this.$socket.id);

    },
    methods: {
        async exitSession() {
            if (!this.exitPending) {
                this.$socket.emit('pause_play', this.machine.id, this.session.distributed_id, this.$socket.id);
                this.exitPending = true;
                //this.$router.push('/platform');
                this.exitTimeout = setTimeout(() => {
                    this.$router.push('/platform');
                }, this.exitTimeoutInterval);
            }
        },
        async resizeEventListener(e) {
            if (this.resizeBounce) {
                clearTimeout(this.resizeBounce);
            }
            this.resizeBounce = setTimeout(() => {
                this.initLayout();
            }, 500);
        },
        async getBalance() {
            //let response = await this.$http.get('players/profile');
            //this.balance = (response.data.balance.amount / 100).toFixed(2);
            let response = await this.$http.get('players/remoteDetails');
            this.balance = response.data.account.cashable;
            this.balanceCurrency = response.data.account.currency.code;
        },
        async clickInsert() {
            await this.getBalance();
            this.showInsert = true;
        },
        async toggleSoundEnabled() {
            this.soundEnabled = (this.soundEnabled) ? false : true;
            localStorage.setItem('soundEnabled', this.soundEnabled);
        },
        async toggleVideoLayout() {
            if (this.videoLayoutKey == 'VL_ONE') {
                this.videoLayoutKey = 'VL_TWO';
            } else {
                this.videoLayoutKey = 'VL_ONE';
            }
            this.layout = null;
            this.videoLayout = null;
            this.initLayout();
        },

        async cashHandpay() {
            this.$socket.emit('resetHandpayAndTransferToWallet', this.$route.params.id);
            this.handpay = null;
        },
        async checkTransactionStatus() {
            let response = await axios.get(`unigens/can_make_cashless_transactions?distributed_session_id=${this.$route.params.id}`);
            return response.data.Result;
        },
        async updatePlayerSocketId(socketId) {
            //console.log('updatePlayerSocketId', socketId);
            let response = await axios.get(`unigens/set_player_remote_session_socket_id?guid=${this.$route.params.id}&playerSocketId=${socketId}`);
        },
        async updateSideSessionPlayerSocketId(socketId) {
            let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&playerSocketId=${socketId}`);
        },
        async toggleSideplayPlaying() {
            let newStatus = (this.sideSession.Status == "RUNNING") ? 'PAUSED' : 'RUNNING';
            let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sessionStatus=${newStatus}`);
        },
        async adjustSidebet(value) {
            console.log('adjusting sidebet');
            try {
                value = parseInt(value);
                let currentBetFactor = this.sideSession.Info.BetFactor;
                value = currentBetFactor + value;
                if (value >= this.sideSession.SideSession.MinBetPercentOfMainBet && value <= this.sideSession.SideSession.MaxBetPercentOfMainBet) {
                    let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGamePaymentPercent=${value}`);
                }
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async adjustSidebetValue(value) {
            console.log('adjusting sidebet');
            try {
                value = parseInt(value);
                let currentBetFactor = this.sideSession.Info.SideGameBettingValue;
                value = currentBetFactor + value;
                if (value >= this.sideSession.SideSession.MinBetValue && value <= this.sideSession.SideSession.MaxBetValue) {
                    let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGameBettingValue=${value}`);
                }
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async setSidebet(value) {
            try {
                switch (value) {
                    case "MAX":
                        value = this.sideSession.SideSession.MaxBetPercentOfMainBet;
                        break;
                    case "MIN":
                        value = this.sideSession.SideSession.MinBetPercentOfMainBet;
                        break;
                    default:
                        break;
                }
                let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGamePaymentPercent=${value}`);
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async setSidebetValue(value) {
            try {
                switch (value) {
                    case "MAX":
                        value = this.sideSession.SideSession.MaxBetValue;
                        break;
                    case "MIN":
                        value = this.sideSession.SideSession.MinBetValue;
                        break;
                    default:
                        break;
                }
                let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGameBettingValue=${value}`);
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async collectSideplay() {
            try {
                // collect
                let balance = this.sideSession.Info.Balance;
                let collectResponse = await this.$http.post('players/backBetting/moneyOut', {
                    sessionGuid: this.sideSession.Guid,
                    amountInMoney: balance,
                });
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async closeSidebetSession() {
            try {
                // collect
                let balance = this.sideSession.Info.Balance;
                let collectResponse = await this.$http.post('players/backBetting/moneyOut', {
                    sessionGuid: this.sideSession.Guid,
                    amountInMoney: balance,
                });

                let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sessionStatus=FINISHED`);
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        replaceInfo(key, object) {
            if (!this.sideSession) {
                let key = object.ObjTextRow1;
                let keys = key.split('.');
                let selector = this;
                for (var i = 0; i < keys.length; i++) {
                    let currentKey = keys[i];
                    selector = selector[currentKey];
                }
                return selector;
            } else {
                return this.sideSession.Info[key];
            }
        },
        getStyle(object) {
            // Fullscreen exception
            if (this.fullscreenMode) {
                if (object.ObjType == 'VideoCanvas') {
                    return {
                        'top': '0px',
                        'left': '0px',
                        'width': '100%',
                        'height': '100%',
                    }
                }
            }
            // Default styles, position and size
            let style = {
                'top': object.ObjTop + 'px',
                'left': object.ObjLeft + 'px',
                'width': object.ObjWidth + 'px',
                'height': object.ObjHeight + 'px',
                'z-index': object.ObjZIndex,
            }

            // Add image backgrounds
            if (object.ObjType == 'Image' || object.ObjType == 'Menu') {
                style = {
                    ...style,
                    'background-image': `url('/images/buttons/${object.ObjTextRow1}')`,
                    'background-size': '100% 100%',
                    'z-index': -1
                }
                /*style.push({
                    'background-image': '@/assets/images/buttons/' + object.ObjTextRow1;    
                });*/
            }

            // Append extra style for text objects
            if (object.ObjType == "Text" || object.ObjType == 'Rectangle' || object.ObjType == 'Button') {
                style = {
                    ...style,
                    ...this.getExtraStyle(object),
                }
            }
            return style;
        },
        getExtraStyle(object) {
            let props = {};
            for (var i = 0; i < object.Properties.length; i++) {
                let key = object.Properties[i].PropertyKey;
                let value = object.Properties[i].PropertyValue;
                if (key == 'text-align') {
                    if (this.hand == 'Right') {
                        value = (value == 'Right') ? 'Left' : 'Right';
                    }
                }
                props[key] = value;
            }
            return props;
        },
        getVisibility(object) {
            // Fullscreen exception
            if (this.fullscreenMode) {
                return (object.ObjType == 'VideoCanvas');
            }
            let check = false;
            if (object.ObjViewState.split(',').includes(this.viewState)) {
                // Apply scopes on tables
                if (object.ObjType == 'Table') {
                    if (object.ObjScope == this.tableScope) {
                        check = true;
                    }
                } else {
                    check = true;
                }
            }
            return check;
        },
        async executeInstruction(object, instruction) {
            console.log('executing instruction ', JSON.stringify(instruction));
            switch (instruction.ExecLogicKeyName) {
                case 'OpenURL':
                    console.log('aici', instruction.ExecLogicValue);
                    window.open(instruction.ExecLogicValue, "_blank");
                    break;
                case 'ApplyVideoLayout':
                    this.videoLayoutKey = instruction.ExecLogicValue;
                    localStorage.setItem('videoLayoutKey', this.videoLayoutKey);
                    this.initLayout();
                    break;
                case 'CloseKeypad':
                    this.$route.query.viewMode = 'Standard';
                    this.initLayout();
                    this.$socket.emit('cast_stop', this.$route.params.id);
                    break;
                case 'ApplyStateLayout':
                    this.viewState = instruction.ExecLogicValue;
                    break;
                case 'ApplyScope':
                    this.tableScope = instruction.ExecLogicValue;
                    break;
                case 'ExecInsertMoney':
                    let check = await this.checkTransactionStatus();
                    if (check) {
                        this.clickInsert();
                    } else {
                        this.$toasted.error("Please take win before attempting this action.");
                    }
                    break;
                case 'SetDeviceHand':
                    this.hand = instruction.ExecLogicValue;
                    localStorage.setItem('hand', this.hand);
                    break;
                case 'SetMenuOrientation':
                    this.menuOrientation = instruction.ExecLogicValue;
                    localStorage.setItem('menuOrientation', this.menuOrientation);
                    break;
                case 'DrawLayout':
                    this.initLayout();
                    break;
                case 'SessionStatus':
                    this.exitSession();

                    break;
                case 'ExecRIBCommand':
                    this.sendRibCommand(object.ObjRIBButtonID);
                    break;
                case 'TryRIBCommand':
                    this.maybeSendRibCommand(object.ObjRIBButtonID);
                    break;
                case 'ShowQRModal':
                    this.showCastKeys = true;
                    break;
                case 'SetFullScreen':
                    if (instruction.ExecLogicValue == "1") {
                        if (document.fullscreenEnabled) {
                            if (!document.fullscreenElement) {
                                document.documentElement.requestFullscreen();
                            } else {
                                document.exitFullscreen();
                            }
                        }
                    }
                    /*this.fullscreenMode = (instruction.ExecLogicValue == "1");*/
                    break;
                case 'SetBet':
                    this.setSidebet(instruction.ExecLogicValue);
                    break;
                case 'AdjustBet':
                    this.adjustSidebet(instruction.ExecLogicValue);
                    break;
                case 'SetBetValue':
                    this.setSidebetValue(instruction.ExecLogicValue);
                    break;
                case 'AdjustBetValue':
                    this.adjustSidebetValue(instruction.ExecLogicValue);
                    break;
                case 'CollectSideplay':
                    this.collectSideplay();
                    break;
            }
        },
        async runObjectEvent(object, event) {
            console.log('RUNNING ', event, 'on', object);
            let action = object.Actions.find(e => {
                return (e.ExecLogicUserAction == event);
            });
            if (!action) {
                console.log('NO INSTRUCTION SET FOR  ', event, ' ON ', object);
                return;
            }
            for (var i = 0; i < action.Instructions.length; i++) {
                let instruction = action.Instructions[i];
                this.executeInstruction(object, instruction);
            }
        },
        async handleObjectMouseUp(object, longclick) {
            clearTimeout(object.holdTimeout);
            let event = 'CLICK';
            if (longclick) {
                event = 'LONGCLICK';
                this.ignoreUp = true;
            } else {
                if (this.ignoreUp == true) {
                    this.ignoreUp = false;
                    return;
                }
            }
            this.runObjectEvent(object, event);
            //let timestamp = moment();
            // Detect longclick
            /*let event = 'CLICK';
            if (object.lastMouseDown) {
                let diff = moment().diff(object.lastMouseDown);
                if (diff > this.longClickTime) {
                    event = 'LONGCLICK';
                }
            }*/
            // Run object EVENT
        },
        async handleObjectMouseDown(object) {
            //let timestamp = moment();
            //object.lastMouseDown = timestamp;
            object.holdTimeout = setTimeout(() => {
                this.handleObjectMouseUp(object, true);
            }, this.longClickTime);
        },
        async initLayout() {
            this.showInsert = false;
            await this.$nextTick();
            await this.getWindow();
            await this.getLayoutData();
            await this.getVideoLayoutData();
            await this.getInsertSize();
        },
        async getInsertSize() {
            // Desktop
            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;
            let scale;
            // Desktop
            if (windowWidth >= 990) {
                scale = Math.min(windowWidth / 1920, 1);
                this.insertType = 'portrait';
                this.insertScale = scale;
            }
            // Mobile
            else {
                if (windowHeight > windowWidth) {
                    // Portarit
                    scale = windowHeight / 915;
                    this.insertType = 'portrait';
                    this.insertScale = scale;
                } else {
                    scale = windowWidth / 915;
                    this.insertType = 'landscape';
                    this.insertScale = scale;
                }
            }

        },
        async getLayoutData() {


            console.log('asdsa', window.innerWidth);

            try {

                if (!this.session) {
                    console.log("INVALID SESSION");
                    return;
                }

                let system = (this.$route.query.system) ? this.$route.query.system : 'Remote';
                let viewMode = (this.$route.query.viewMode) ? this.$route.query.viewMode : 'Standard';
                let designStyle = (this.$route.query.designStyle) ? this.$route.query.designStyle : 'Basic';

                let machineId;
                if (this.$route.query.testing) {
                    machineId = 82;
                } else {
                    machineId = this.machine.id;
                }

                let orientation = (window.innerWidth - window.innerHeight > 0) ? 'Landscape' : 'Portrait';
                let response = await axios.get(`unigens/get_interface_objects?miSystem=${system}&miMachine=${machineId}&miLang=1&miAreaWidth=${window.innerWidth}&miAreaHeight=${window.innerHeight}&miDevice=${this.$route.query.device}&miOrientation=${orientation}&miHand=${this.hand}&menuDirection=${this.menuOrientation}&viewMode=${viewMode}&designStyle=${designStyle}`);
                this.layout = response.data.Result.InterfaceObjects;
                // Set layout default viewstate
                let defaultState = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "BaseViewState");
                });
                if (defaultState) {
                    this.viewState = defaultState.Value;
                }
                // Set layout default hand
                let defaultHand = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "DeviceHand");
                });
                if (defaultHand) {
                    if (!localStorage.getItem('hand')) {
                        this.hand = defaultHand.Value;
                        localStorage.setItem('hand', this.hand);
                    }
                }
                // Set default table scope
                let tableScope = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "TableScope");
                })
                if (tableScope) {
                    this.tableScope = tableScope.Value;
                }


                // Set layout default menu orientation
                let defaultMenuOrientation = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "MenuOrientation");
                });
                if (defaultMenuOrientation) {
                    if (!localStorage.getItem('menuOrientation')) {
                        this.menuOrientation = defaultMenuOrientation.Value;
                        localStorage.setItem('menuOrientation', this.menuOrientation);
                    }
                }


                // Set layout default menu orientation
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async getVideoLayoutData() {
            try {

                if (!this.session) {
                    console.log("INVALID SESSION");
                    return;
                }

                let orientation = (this.viewport.width - this.viewport.height > 0) ? 'Landscape' : 'Portrait';
                if (!this.lastOrientation) {
                    this.lastOrientation = orientation;
                }

                let machineId;
                if (this.$route.query.testing) {
                    machineId = 82;
                } else {
                    machineId = this.machine.id;
                }

                /*if (this.$route.query.testing || (this.$route.query.system && this.$route.query.system == 'SidePlayer')) {
                    console.log('aici');
                    let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/82');
                    this.machine = response.data;
                } else {
                    let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/' + this.session.machine.id);
                    this.machine = response.data;
                }*/

                let response = await axios.get(`unigens/get_video_layouts?videoLayoutKey=${this.videoLayoutKey}&currentOrientation=${this.lastOrientation}&newOrientation=${orientation}&machineId=${machineId}`);
                this.videoLayout = response.data.Result;
                this.lastOrientation = orientation;
                if (this.videoLayout.length > 0) {
                    this.videoLayoutKey = this.videoLayout[0].VLayoutKey;
                    localStorage.setItem('videoLayoutKey', this.videoLayoutKey);
                }
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async getWindow() {
            this.viewport.width = window.innerWidth;
            this.viewport.height = window.innerHeight;
            // Implement detect mobile later
            this.viewport.mobile = false;
            console.log('current viewport', this.viewport);
        },
        async getSideSession() {
            try {
                let response = await this.$http.get(`unigens/get_player_side_session_details?playerSideSessionGuid=${this.$route.params.id}`);
                this.sideSession = response.data.Result;
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async getMachine() {
            try {

                let res = await this.$http.get(process.env.VUE_APP_API_SIGNAL + '/api/sessions/' + this.$route.params.id);
                this.session = res.data;

                if (!this.session) {
                    console.log("INVALID SESSION");
                    this.$toasted.info('The session has ended');
                    this.$router.push('/');
                    return;
                }

                if (this.$route.query.testing || (this.$route.query.system && this.$route.query.system == 'SidePlayer')) {
                    console.log('aici');
                    let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/82');
                    this.machine = response.data;
                } else {
                    let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/' + this.session.machine.id);
                    console.log('resdata', response.data);
                    this.machine = response.data;
                }


                // TODO: For testing only remove me later
                if (this.$route.query.style) {
                    this.machine.bet_button_style = this.$route.query.style;
                }

                this.$root.$emit('setHeaderDetails', {
                    manufacturer: this.machine.manufacturer.name,
                    slotNumber: this.machine.number,
                })

            } catch (error) {
                this.$toasted.error("Machine error");
                console.log(error);
            }
        },
        getIcon(name) {
            return require('@/assets/images/icons/mute_on.svg');
        },
        insert(amount) {
            // Sidebet exception
            if (this.$route.query.system == 'SidePlayer') {
                this.insertSidebet(amount);
                return;
            }

            if (amount == 'ALL_IN') {
                amount = this.balance;
            }

            // Normal slot insert
            this.loading = true;
            this.$http.post(process.env.VUE_APP_API_SIGNAL + '/api/sessions/addFunds', {
                    session_id: this.$route.params.id,
                    amount: parseInt(amount),
                })
                .then(res => {

                    this.loading = false;
                    this.showAddFunds = false;

                    if (res.data.success) {
                        this.$toasted.success('Credit added');
                    } else {
                        this.$toasted.error(res.data.reason);
                    }

                    this.showInsert = false;

                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;

                    this.showInsert = false;
                });
        },
        async insertSidebet(amount) {
            try {
                let response = this.$http.post('players/backBetting/moneyIn', {
                    sessionGuid: this.sideSession.Guid,
                    amountInMoney: amount,
                });
                this.showInsert = false;
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
                this.showInsert = false;
            }
        },
        async maybeSendRibCommand(command) {
            let check = await this.checkTransactionStatus();
            if (check) {
                this.sendRibCommand(command);
            } else {
                this.$toasted.error("Please take win before attempting this action.");
            }
            console.log('maybersend rib command', check);
        },
        sendRibCommand(command) {
            var self = this;
            if (this.keys_blocked) {
                return;
            }
            var smib = this.machine.controlBoard;
            this.$socket.emit('send_command', smib, command);
            this.keys_blocked = true;
            setTimeout(function() {
                self.keys_blocked = false;
            }, 300);
        },
        touchStart(positions) {
            var smib = this.machine.controlBoard;
            //let mainStream = this.machine.videoStreams.find(e => e.videoStreamType.code == 'Game');
            this.$socket.emit('touch_start', smib, this.session.distributedId, positions);
        },
    }
}
</script>