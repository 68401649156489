<template>
    <div class="view menu">
        <div class="navigation" data-aos="fade-right" :class="{'hide-mobile': mobileStep == 2}">
            <div class="player" v-if="player">
                <div class="name">
                    {{ player.fullName }}
                </div>
                <div class="id">
                    ID: {{ player.user.userName }}
                </div>
                <div class="balance">
                    <div class="value">
                        {{ player.account.balance | moneyFormat(true) }} {{ player.account.currency.code }}
                    </div>
                    <div class="label">
                        current balance
                    </div>
                </div>
            </div>
            <div class="menu">
                <div class="items">
                    <div class="item">
                        <router-link to="/platform/">
                            Main Lobby
                        </router-link>
                    </div>
                    <div class="item" @click="navigate('/platform/menu/profile')" :class="{'active': $route.path.includes('profile')}">
                        Profile
                    </div>
                    <div class="item" @click="navigate('/platform/menu/transactions')" :class="{'active': $route.path.includes('transactions')}">
                        Transactions History
                    </div>
                    <div class="item" @click="navigate('/platform/menu/sessions')" :class="{'active': $route.path.includes('sessions')}">
                        Game History
                    </div>
                    <div @click="logout()" class="item">
                        Logout
                    </div>
                </div>
            </div>
            <div class="login" v-if="player">
                <div class="title">CURRENT LOGIN DETAILS</div>
                <div class="details">
                    <div class="detail">
                        <div class="label">
                            IP Address:
                        </div>
                        <div class="value">
                            {{ player.user.lastLogin.remoteIpAddress }}
                        </div>
                    </div>
                    <div class="detail">
                        <div class="label">
                            Login time:
                        </div>
                        <div class="value">
                            {{ player.user.lastLogin.creationTime | moment("YYYY-MM-DD HH:mm:ss") }}
                            ({{ player.user.lastLogin.creationTime | moment("from", "now") }})
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content" v-if="player" :class="{'hide-mobile': mobileStep == 1}">
            <router-view @update="getPlayer" :player="player" data-aos="fade-in" />
        </div>
    </div>
</template>
<style lang="scss">
@import "MenuView.scss";
</style>
<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
    computed: mapGetters(['loggedIn', 'getPair']),
    data() {
        return {
            player: null,
            mobileStep: 1,
        }
    },
    watch: {
        '$route.path': {
            handler(newPath) {
                if (newPath == '/platform/menu') {
                    this.mobileStep = 1;
                }
            }
        }
    },
    async mounted() {
        this.getPlayer();
    },
    methods: {
        ...mapActions(['logout']),

        async navigate(url) {
            this.mobileStep = 2;
            this.$router.push(url);
            this.$forceUpdate();

        },
        async getPlayer() {
            try {
                let response = await axios.get('players/remoteDetails');
                this.player = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        }
    }
}
</script>