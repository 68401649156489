<template>
    <div class="view app" :style="'background-image: url(' + background + ')'" :class="size">
        <header-bar v-if="$route.name != 'PlayRoute' && $route.name != 'LayoutSim' && $route.name != 'LayoutTest' " />
        <header-play class="d-none d-sm-none d-md-none d-lg-block" v-if="$route.name == 'PlayRoute'" />
        <div class="main view" :class="{'play': this.$route.name == 'PlayRoute'}">
            <router-view />
            <div class="notifications">
                <div class="notification" v-for="notification in notifications">{{ notification.message }}</div>
            </div>
        </div>
        <div class="overlays">
            <div class="overlay popup" v-if="showPopup">
            	<div class="box" data-aos="fade-down">
            		<div class="title">
            			<div class="pull-right">
            				<img @click="closePopup" width="40px" src="@/assets/images/close_popup.png" alt="">
            			</div>
            			CAMPAIGNS
            		</div>
            		<div class="slides">
            			<carousel :navigate-to="customNav" :per-page="1" :loop="true" autoplay :autoplay-timeout="3000">
						  <slide>
						    <img class="slide" src="@/assets/images/popups/popup1.jpg" alt="">
						  </slide>
						  <slide>
						    <img class="slide" src="@/assets/images/popups/popup2.jpg" alt="">
						  </slide>
						  <slide>
						    <img class="slide" src="@/assets/images/popups/popup3.jpg" alt="">
						  </slide>
						  <slide>
						    <img class="slide" src="@/assets/images/popups/popup4.jpg" alt="">
						  </slide>
						</carousel>
            		</div>
            	</div>
            </div>
        </div>
    </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import HeaderBar from '@/components/Header/HeaderBar.vue';
import HeaderPlay from '@/components/Header/HeaderPlay.vue';
import { mapGetters, mapActions } from 'vuex';
import { Buffer } from 'buffer';
export default {
    name: "SavoyApp",
    components: {
        HeaderBar,
        HeaderPlay,
        Carousel,
    	Slide,
    },
    data() {
        return {
            notifications: [],
            background: null,
            backgroundLarge: null,
            backgroundSmall: null,
            size: 'landscape',
            showPopup: false,
            customNav: null,
        }
    },
    sockets: {
        notification: function(notification) {
            console.log('notification', notification);
            this.notifications.push(notification);
        },
        tvbox_disconnected: function(device_id) {
            this.setPair(false);
        }
    },
    async mounted() {
        await this.getBackground();
        await this.getBackgroundSmall();
        this.handleWindowResize();
        window.addEventListener('keydown', this.handleKeyDownEvent);
        await this.$nextTick();
        window.addEventListener('resize', this.handleWindowResize);
        // Handle campaign popups
        if(process.env.VUE_APP_SHOW_POPUP == "true") {
        	let cookie = this.getCookie("hide_popup");
        	if(!cookie) {
	        	this.showPopup = true;
	        	setTimeout(() => {
	        		this.customNav = 0;
	        	},50);
        	}
        }
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDownEvent);
        window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {
    	setCookie(cname, cvalue, exdays) {
		  const d = new Date();
		  d.setTime(d.getTime() + (exdays*24*60*60*1000));
		  let expires = "expires="+ d.toUTCString();
		  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		},
		getCookie(cname) {
		  let name = cname + "=";
		  let decodedCookie = decodeURIComponent(document.cookie);
		  let ca = decodedCookie.split(';');
		  for(let i = 0; i <ca.length; i++) {
		    let c = ca[i];
		    while (c.charAt(0) == ' ') {
		      c = c.substring(1);
		    }
		    if (c.indexOf(name) == 0) {
		      return c.substring(name.length, c.length);
		    }
		  }
		  return "";
		},
    	closePopup() {
    		this.showPopup = false;
    		this.setCookie("hide_popup", "true", 1);
    	},
        handleWindowResize() {
            if (window.innerWidth > window.innerHeight) {
                this.background = this.backgroundLarge;
                this.size = 'landscape';
            } else {
                this.background = this.backgroundSmall;
                this.size = 'portrait';
            }
        },
        handleKeyDownEvent(event) {
            this.$root.$emit('keydown', event);
        },
        ...mapActions(['setPair']),
        async getBackground() {
            try {
                this.backgroundLarge = process.env.VUE_APP_API_REVOLUTION + "/CasinoPlayer/SYS_LOBBY_BACKGROUND.jpg";
                /*
                let response = await this.$http({
                    method: 'get',
                    url: '/unigens/download_picture_by_key?path=CASINO_PLAYER&key=SYS_LOBBY_BACKGROUND',
                    responseType: 'arraybuffer',
                });

                let mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this.backgroundLarge = 'data:' + mimeType + ';base64,' + imgBase64;*/
            } catch (error) {
                console.log(error);
            }
        },
        async getBackgroundSmall() {
            try {
                this.backgroundSmall = process.env.VUE_APP_API_REVOLUTION + "/CasinoPlayer/SYS_LOBBY_BACKGROUND_MOBILE_PORTRAIT.jpg";
                /*let response = await this.$http({
                    method: 'get',
                    url: '/unigens/download_picture_by_key?path=CASINO_PLAYER&key=SYS_LOBBY_BACKGROUND_MOBILE_PORTRAIT',
                    responseType: 'arraybuffer',
                });

                let mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this.backgroundSmall = 'data:' + mimeType + ';base64,' + imgBase64;*/
            } catch (error) {
                console.log(error);
            }
        },



    }

}
</script>