<template>
    <div class="qr-code">
        <div class="qr-holder">
            <canvas id="qr"></canvas>
            <div class="text">
                
            </div>
        </div>
    </div>
</template>
<style scoped>
.qr-holder {
    text-align: center;
    padding: 50px;
}
#qr {
    display: inline-block;

}
</style>
<script>
import QRious from 'qrious';
export default {
    props: ['session_id'],
    data() {
        return {
            player: null,
            qrValue: null,
        }
    },
    async mounted() {

        this.qrValue = `https://casinosavoy.cloud.ro/platform/layout/${this.session_id}?device=Desktop&viewMode=keypad`
        var qr = new QRious({
            element: document.getElementById('qr'),
            value: this.qrValue,
            size: 200,
        });
    },
}
</script>