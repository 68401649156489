import axios from 'axios';

const state = {

	loading: 0, // 0 - not loading, 1 - live loading, 2 full loading 
	auth: {
		loggedIn: false, 
		token: (JSON.parse(localStorage.getItem('token'))) || null ,
	},
	pair: false,

};

const getters = { 
	getPair(state) {
		return state.pair;
	},
	getToken(state) {
		return state.auth.token;
	},
	loggedIn(state) {
		return state.auth.token !== null
	},  
	
};  
 
const actions = {
	setPair({ commit }, pair) {
		commit('SET_PAIR', pair);
	},
	setLoading({ commit }, loading) {
		commit('SET_LOADING', loading);
	},

	login({ commit }, credentials) {

		credentials.clientCode = 'R2ADevWebCasino';

		return new Promise((resolve, reject) => {

			axios.post('application/login', credentials ).then(response => {

				// Check for token in API response
				if(response.data.access_token) {

					// Add token to axios default headers
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

					// Save token to local storage
					localStorage.setItem('token', JSON.stringify(response.data));

					// Commit the token to //Vuex Store
					commit('SET_TOKEN', response.data);
					resolve(response);

					//Vue.toasted.success('Logged in successfully.');  

				} 
				
				}).catch(error => {
					
					if(!error.response.data) {
						//Vue.toasted.error('There was an error processing your request.', { icon : { name : 'report_problem' } });     
					} else {
						//Vue.toasted.error(error.response.data.error);     
					}

					reject(error);
				});

		});




		
	},


 
	async logout({ commit }) { 

		if(this.getters.loggedIn) {

			commit('DESTROY_TOKEN');
			localStorage.removeItem('token');
			
			
			window.location.reload();

			


			/*const response = await axios.post('logout').then(response => {
		        
				commit('DESTROY_TOKEN');
				localStorage.removeItem('token');
				//Vue.toasted.success('Logged out.'); 

			}).catch(error => {		

				console.log(error.response.data);

				// Unauthorized/token expired
				if(error.response.status === 401) {
					commit('DESTROY_TOKEN'); 
					localStorage.removeItem('token');
					
					////Vue.toasted.success('Logged out.'); 
				} else {
					//Vue.toasted.error('Error logging out.'); 
				}
				
				
			});*/

		}

	}
};

const mutations = {
	SET_PAIR(state, pair) {
		state.pair = pair;
	},
	SET_TOKEN(state, token) { 
		state.auth.token = token;  
		state.auth.loggedIn = true;
	},
	DESTROY_TOKEN(state) {
      state.auth.token = null;
    },
    SET_LOADING(state, loading) {
    	state.loading = loading;  
    },
	
}; 

export default {
	state,
	getters,
	actions, 
	mutations
}