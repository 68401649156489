<template>
    <div class="view layout-test">
        <div class="grid">
            <div class="line" v-for="line in grid.lines" :style="line.style"></div>
        </div>
        <div class="canvas" v-if="layout">
            <!-- Default Elements -->
            <div @pointerdown="handleObjectMouseDown(object)" @pointerup="handleObjectMouseUp(object)" class="object" v-for="object in layout" v-show="getVisibility(object)" :style="getStyle(object)" v-if="!customElements.includes(object.ObjType)">
                <div class="label">
                    {{ object.ObjKey }}
                </div>
                <div class="dump" v-if="object.ObjType == 'VideoCanvas'">
                    <div class="item" v-for="item in dump">
                        {{ item.Key }}: {{ item.Value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "LayoutTestView.scss";
</style>
<script>
import axios from 'axios';
import ViewStream from '../Play/components/ViewStream.vue';
import moment from 'moment';
import QRCode from '@/components/Qrcode/Qrcode.vue';
import HeaderPlay from '@/components/Header/HeaderPlay.vue';
export default {
    data() {
        return {
            viewport: {
                width: null,
                height: null,
                mobile: false,
            },
            grid: {
                x: 0,
                y: 0,
                lines: [],
            },
            dump: null,
            resizeBounce: false,
            layout: null,
            viewState: '1',
            longClickTime: 1000,
            showInsert: false,
            hand: 'Right',
            menuOrientation: 'Vertical',
            balance: 0,
            session: null,
            machine: null,
            loading: false,
            loading_status: '',
            keys_blocked: false,
            showCastKeys: false,
            lastOrientation: null,
            videoLayout: null,
            videoLayoutKey: 'VL_3L',
            videoStreams: ['Game', 'Floor', 'Jackpot'],
            viewMode: 'Standard',
            ignoreUp: false,
            sideSession: null,
            tableScope: null,
            casting: false,
            customElements: ["ToggleSideplayPlaying"],
        }
    },
    components: {
        HeaderPlay,
        ViewStream,
        'qr-code': QRCode,
    },
    sockets: {
        refreshPlayerSideSessionsDetails: function() {
            console.log('refreshPlayerSideSessionsDetails');
            this.getSideSession();
        },
        cast_stop: function() {
            //this.$router.push('/');
        },
        cast_start: function() {
            console.log('cast starting');
            this.showCastKeys = false;
            this.$route.query.viewMode = 'CastView';
            this.initLayout();

        },
        closedSession: function() {
            this.$router.push('/');
        },
        startLoading: function(status) {
            console.log('startLoading');
            this.loading = true;
            this.loading_status = status;
        },
        stopLoading: function() {
            console.log('stopLoading');
            this.loading = false;
        },
        session_message: function(message) {
            console.log(message);
            if (message.session_id === this.session.id) {
                if (message.type === 'success') {
                    this.$toasted.success(message.message);
                } else if (message.type === 'error') {
                    this.$toasted.error(message.message);
                }
            }
        },
    },
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#In-Component-Guards
    async beforeDestroy() {
        console.log('query system', this.$route.query.system);
        await this.closeSidebetSession();
        this.$socket.emit('pause_play', this.machine.id, this.session.distributed_id, this.$socket.id);

    },
    async mounted() {

        // Load local storage variables
        if (localStorage.getItem('hand')) {
            this.hand = localStorage.getItem('hand');
        }
        if (localStorage.getItem('menuOrientation')) {
            this.menuOrientation = localStorage.getItem('menuOrientation');
        }
        if (localStorage.getItem('videoLayoutKey')) {
            this.videoLayoutKey = localStorage.getItem('videoLayoutKey');
        } else {
            localStorage.setItem('videoLayoutKey', this.videoLayoutKey);
        }


        // Update socket details for sidesession
        if (this.$route.query.system && this.$route.query.system == 'SidePlayer') {
            await this.getSideSession();
            // maybe update player socket
            if (this.$socket.id != this.sideSession.PlayerSocketId) {
                this.updateSideSessionPlayerSocketId(this.$socket.id);
            }
        }

        await this.getMachine();
        await this.initLayout();

        // Update player socket id on normal sessions
        if (!this.$route.query.system && this.$route.query.viewMode != 'keypad') {
            this.updatePlayerSocketId(this.$socket.id);
        }

        // Add resize event with bounce
        window.addEventListener("resize", (e) => {
            if (this.resizeBounce) {
                clearTimeout(this.resizeBounce);
            }
            this.resizeBounce = setTimeout(() => {
                this.initLayout();
            }, 500);
        });

        // Emit keypad events
        if (this.$route.query.viewMode == 'keypad') {
            console.log('cast_start', this.$route.params.id);
            this.$socket.emit('cast_start', this.$route.params.id);
        }

    },
    methods: {
        async generateGrid() {
            this.grid.lines = [];
            
            // generate x lines 
            let xMulti = window.innerWidth / this.grid.x;
            for (let i = 0; i <= this.grid.x - 1; i++) {
                let xPos = parseInt(i * xMulti);
                this.grid.lines.push({
                    style: {
                        'top': '0px',
                        'left': xPos + 'px',
                        'width': '1px',
                        'height': '100%',
                    }
                });
            }
            // generate y lines
            let yMulti = window.innerHeight / this.grid.y;
            for (let i = 0; i <= this.grid.y - 1; i++) {
                let yPos = parseInt(i * yMulti);
                this.grid.lines.push({
                    style: {
                        'top': yPos + 'px',
                        'left': '0px',
                        'width': '100%',
                        'height': '1px',
                    }
                });
            }




        },
        async checkTransactionStatus() {
            let response = await axios.get(`unigens/can_make_cashless_transactions?distributed_session_id=${this.$route.params.id}`);
            return response.data.Result;
        },
        async updatePlayerSocketId(socketId) {
            //console.log('updatePlayerSocketId', socketId);
            let response = await axios.get(`unigens/set_player_remote_session_socket_id?guid=${this.$route.params.id}&playerSocketId=${socketId}`);
        },
        async updateSideSessionPlayerSocketId(socketId) {
            let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&playerSocketId=${socketId}`);
        },
        async toggleSideplayPlaying() {
            let newStatus = (this.sideSession.Status == "RUNNING") ? 'PAUSED' : 'RUNNING';
            let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sessionStatus=${newStatus}`);
        },
        async adjustSidebet(value) {
            console.log('adjusting sidebet');
            try {
                value = parseInt(value);
                let currentBetFactor = this.sideSession.Info.BetFactor;
                value = currentBetFactor + value;
                if (value >= this.sideSession.SideSession.MinBetPercentOfMainBet && value <= this.sideSession.SideSession.MaxBetPercentOfMainBet) {
                    let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGamePaymentPercent=${value}`);
                }
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async adjustSidebetValue(value) {
            console.log('adjusting sidebet');
            try {
                value = parseInt(value);
                let currentBetFactor = this.sideSession.Info.SideGameBettingValue;
                value = currentBetFactor + value;
                if (value >= this.sideSession.SideSession.MinBetValue && value <= this.sideSession.SideSession.MaxBetValue) {
                    let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGameBettingValue=${value}`);
                }
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async setSidebet(value) {
            try {
                switch (value) {
                    case "MAX":
                        value = this.sideSession.SideSession.MaxBetPercentOfMainBet;
                        break;
                    case "MIN":
                        value = this.sideSession.SideSession.MinBetPercentOfMainBet;
                        break;
                    default:
                        break;
                }
                let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGamePaymentPercent=${value}`);
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async setSidebetValue(value) {
            try {
                switch (value) {
                    case "MAX":
                        value = this.sideSession.SideSession.MaxBetValue;
                        break;
                    case "MIN":
                        value = this.sideSession.SideSession.MinBetValue;
                        break;
                    default:
                        break;
                }
                let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sideGameBettingValue=${value}`);
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async collectSideplay() {
            try {
                // collect
                let balance = this.sideSession.Info.Balance;
                let collectResponse = await this.$http.post('players/backBetting/moneyOut', {
                    sessionGuid: this.sideSession.Guid,
                    amountInMoney: balance,
                });
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async closeSidebetSession() {
            try {
                // collect
                let balance = this.sideSession.Info.Balance;
                let collectResponse = await this.$http.post('players/backBetting/moneyOut', {
                    sessionGuid: this.sideSession.Guid,
                    amountInMoney: balance,
                });

                let response = await axios.get(`unigens/set_player_side_session?guid=${this.sideSession.Guid}&sessionStatus=FINISHED`);
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        replaceInfo(key, object) {

            if (!this.sideSession) {
                return null;
            }


            //return key;
            return this.sideSession.Info[key];
        },
        getStyle(object) {
            // Fullscreen exception
            if (this.fullscreenMode) {
                if (object.ObjType == 'VideoCanvas') {
                    return {
                        'top': '0px',
                        'left': '0px',
                        'width': '100%',
                        'height': '100%',
                    }
                }
            }
            // Default styles, position and size
            let style = {
                'top': object.ObjTop + 'px',
                'left': object.ObjLeft + 'px',
                'width': object.ObjWidth + 'px',
                'height': object.ObjHeight + 'px',
            }
            // Append extra style for text objects
            if (object.ObjType == "Text") {
                style = {
                    ...style,
                    ...this.getExtraStyle(object),
                }
            }
            return style;
        },
        getExtraStyle(object) {
            let props = {};
            for (var i = 0; i < object.Properties.length; i++) {
                let key = object.Properties[i].PropertyKey;
                let value = object.Properties[i].PropertyValue;
                if (key == 'text-align') {
                    if (this.hand == 'Right') {
                        value = (value == 'Right') ? 'Left' : 'Right';
                    }
                }
                props[key] = value;
            }
            return props;
        },
        getVisibility(object) {
            // Fullscreen exception
            if (this.fullscreenMode) {
                return (object.ObjType == 'VideoCanvas');
            }
            let check = false;
            if (object.ObjViewState.split(',').includes(this.viewState)) {
                // Apply scopes on tables
                if (object.ObjType == 'Table') {
                    if (object.ObjScope == this.tableScope) {
                        check = true;
                    }
                } else {
                    check = true;
                }
            }
            return check;
        },
        async executeInstruction(object, instruction) {
            console.log('executing instruction ', JSON.stringify(instruction));
            switch (instruction.ExecLogicKeyName) {
                case 'OpenURL':
                    console.log('aici', instruction.ExecLogicValue);
                    window.open(instruction.ExecLogicValue, "_blank");
                    break;
                case 'ApplyVideoLayout':
                    this.videoLayoutKey = instruction.ExecLogicValue;
                    localStorage.setItem('videoLayoutKey', this.videoLayoutKey);
                    this.initLayout();
                    break;
                case 'CloseKeypad':
                    this.$route.query.viewMode = 'Standard';
                    this.initLayout();
                    this.$socket.emit('cast_stop', this.$route.params.id);
                    break;
                case 'ApplyStateLayout':
                    this.viewState = instruction.ExecLogicValue;
                    break;
                case 'ApplyScope':
                    this.tableScope = instruction.ExecLogicValue;
                    break;
                case 'ExecInsertMoney':
                    let check = await this.checkTransactionStatus();
                    if (check) {
                        this.showInsert = true;
                    } else {
                        this.$toasted.error("Please take win before attempting this action.");
                    }
                    break;
                case 'SetDeviceHand':
                    this.hand = instruction.ExecLogicValue;
                    localStorage.setItem('hand', this.hand);
                    break;
                case 'SetMenuOrientation':
                    this.menuOrientation = instruction.ExecLogicValue;
                    localStorage.setItem('menuOrientation', this.menuOrientation);
                    break;
                case 'DrawLayout':
                    this.initLayout();
                    break;
                case 'SessionStatus':
                    this.$router.push('/platform');
                    break;
                case 'ExecRIBCommand':
                    this.sendRibCommand(object.ObjRIBButtonID);
                    break;
                case 'TryRIBCommand':
                    this.maybeSendRibCommand(object.ObjRIBButtonID);
                    break;
                case 'ShowQRModal':
                    this.showCastKeys = true;
                    break;
                case 'SetFullScreen':
                    this.fullscreenMode = (instruction.ExecLogicValue == "1");
                    break;
                case 'SetBet':
                    this.setSidebet(instruction.ExecLogicValue);
                    break;
                case 'AdjustBet':
                    this.adjustSidebet(instruction.ExecLogicValue);
                    break;
                case 'SetBetValue':
                    this.setSidebetValue(instruction.ExecLogicValue);
                    break;
                case 'AdjustBetValue':
                    this.adjustSidebetValue(instruction.ExecLogicValue);
                    break;


                case 'CollectSideplay':
                    this.collectSideplay();
                    break;
            }
        },
        async runObjectEvent(object, event) {
            console.log('RUNNING ', event, 'on', object);
            let action = object.Actions.find(e => {
                return (e.ExecLogicUserAction == event);
            });
            if (!action) {
                console.log('NO INSTRUCTION SET FOR  ', event, ' ON ', object);
                return;
            }
            for (var i = 0; i < action.Instructions.length; i++) {
                let instruction = action.Instructions[i];
                this.executeInstruction(object, instruction);
            }
        },
        async handleObjectMouseUp(object, longclick) {
            clearTimeout(object.holdTimeout);
            let event = 'CLICK';
            if (longclick) {
                event = 'LONGCLICK';
                this.ignoreUp = true;
            } else {
                if (this.ignoreUp == true) {
                    this.ignoreUp = false;
                    return;
                }
            }
            this.runObjectEvent(object, event);
        },
        async handleObjectMouseDown(object) {
            //let timestamp = moment();
            //object.lastMouseDown = timestamp;
            object.holdTimeout = setTimeout(() => {
                this.handleObjectMouseUp(object, true);
            }, this.longClickTime);
        },
        async initLayout() {
            await this.getWindow();
            await this.getLayoutData();
            //await this.getVideoLayoutData();
            await this.generateGrid();
        },
        async getLayoutData() {
            try {
                let system = (this.$route.query.system) ? this.$route.query.system : 'Remote';
                let viewMode = (this.$route.query.viewMode) ? this.$route.query.viewMode : 'Standard';
                let designStyle = (this.$route.query.designStyle) ? this.$route.query.designStyle : 'Basic';
                let machineId;

                machineId = 78;


                let orientation = (window.innerWidth - window.innerHeight > 0) ? 'Landscape' : 'Portrait';
                let response = await axios.get(`unigens/get_interface_objects?miSystem=${system}&miMachine=${machineId}&miLang=1&miAreaWidth=${window.innerWidth}&miAreaHeight=${window.innerHeight}&miDevice=${this.$route.query.device}&miOrientation=${orientation}&miHand=${this.hand}&menuDirection=${this.menuOrientation}&viewMode=${viewMode}&designStyle=${designStyle}`);
                this.layout = response.data.Result.InterfaceObjects;
                this.dump = response.data.Result.InterfaceActions;

                // Set grid options
                let gridXDan = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === 'Master H Cells');
                })
                if (gridXDan) {
                    this.grid.x = parseInt(gridXDan.Value);
                }

                let gridYDan = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === 'Master V Cells');
                });
                if (gridYDan) {
                    this.grid.y = parseInt(gridYDan.Value);
                }

                // Set layout default viewstate
                let defaultState = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "BaseViewState");
                });
                if (defaultState) {
                    this.viewState = defaultState.Value;
                }
                // Set layout default hand
                let defaultHand = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "DeviceHand");
                });
                if (defaultHand) {
                    if (!localStorage.getItem('hand')) {
                        this.hand = defaultHand.Value;
                        localStorage.setItem('hand', this.hand);
                    }
                }
                // Set default table scope
                let tableScope = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "TableScope");
                })
                if (tableScope) {
                    this.tableScope = tableScope.Value;
                }


                // Set layout default menu orientation
                let defaultMenuOrientation = response.data.Result.InterfaceActions.find(e => {
                    return (e.Key === "MenuOrientation");
                });
                if (defaultMenuOrientation) {
                    if (!localStorage.getItem('menuOrientation')) {
                        this.menuOrientation = defaultMenuOrientation.Value;
                        localStorage.setItem('menuOrientation', this.menuOrientation);
                    }
                }


                // Set layout default menu orientation
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async getVideoLayoutData() {
            try {
                let orientation = (this.viewport.width - this.viewport.height > 0) ? 'Landscape' : 'Portrait';
                if (!this.lastOrientation) {
                    this.lastOrientation = orientation;
                }
                /*if (this.$route.query.testing || (this.$route.query.system && this.$route.query.system == 'SidePlayer')) {
                    console.log('aici');
                    let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/78');
                    this.machine = response.data;
                } else {
                    let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/' + this.session.machine.id);
                    this.machine = response.data;
                }*/
                let response = await axios.get(`unigens/get_video_layouts?videoLayoutKey=${this.videoLayoutKey}&currentOrientation=${this.lastOrientation}&newOrientation=${orientation}&totalEGMs=3`);
                this.videoLayout = response.data.Result;
                this.lastOrientation = orientation;
                if (this.videoLayout.length > 0) {
                    this.videoLayoutKey = this.videoLayout[0].VLayoutKey;
                    localStorage.setItem('videoLayoutKey', this.videoLayoutKey);
                }
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async getWindow() {
            this.viewport.width = window.innerWidth;
            this.viewport.height = window.innerHeight;
            // Implement detect mobile later
            this.viewport.mobile = false;
            console.log('current viewport', this.viewport);
        },
        async getSideSession() {
            try {
                let response = await this.$http.get(`unigens/get_player_side_session_details?playerSideSessionGuid=${this.$route.params.id}`);
                this.sideSession = response.data.Result;
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async getMachine() {
            try {

                let res = await this.$http.get(process.env.VUE_APP_API_SIGNAL + '/api/sessions/' + this.$route.params.id);
                this.session = res.data;

                console.log(this.session);
                let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/78');
                this.machine = response.data;

                // TODO: For testing only remove me later
                if (this.$route.query.style) {
                    this.machine.bet_button_style = this.$route.query.style;
                }

                this.$root.$emit('setHeaderDetails', {
                    manufacturer: this.machine.manufacturer.name,
                    slotNumber: this.machine.number,
                })

            } catch (error) {
                this.$toasted.error("Machine error");
                console.log(error);
            }
        },
        getIcon(name) {
            return require('@/assets/images/icons/mute_on.svg');
        },
        insert(amount) {
            // Sidebet exception
            if (this.$route.query.system == 'SidePlayer') {
                this.insertSidebet(amount);
                return;
            }
            // Normal slot insert
            this.loading = true;
            this.$http.post(process.env.VUE_APP_API_SIGNAL + '/api/sessions/addFunds', {
                    session_id: this.$route.params.id,
                    amount: amount,
                    socket_id: this.$socket.id,
                })
                .then(res => {

                    this.loading = false;
                    this.showAddFunds = false;

                    if (res.data.success) {
                        this.$toasted.success('Credit added');
                    } else {
                        this.$toasted.error(res.data.reason);
                    }

                    this.showInsert = false;

                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;

                    this.showInsert = false;
                });
        },
        async insertSidebet(amount) {
            try {
                let response = this.$http.post('players/backBetting/moneyIn', {
                    sessionGuid: this.sideSession.Guid,
                    amountInMoney: amount,
                });
                this.showInsert = false;
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
                this.showInsert = false;
            }
        },
        async maybeSendRibCommand(command) {
            let check = await this.checkTransactionStatus();
            if (check) {
                this.sendRibCommand(command);
            } else {
                this.$toasted.error("Please take win before attempting this action.");
            }
            console.log('maybersend rib command', check);
        },
        sendRibCommand(command) {
            var self = this;
            if (this.keys_blocked) {
                return;
            }
            var smib = this.machine.controlBoard;
            this.$socket.emit('send_command', smib, command);
            this.keys_blocked = true;
            setTimeout(function() {
                self.keys_blocked = false;
            }, 300);
        },
        touchStart(positions) {
            var smib = this.machine.controlBoard;
            //let mainStream = this.machine.videoStreams.find(e => e.videoStreamType.code == 'Game');
            this.$socket.emit('touch_start', smib, this.session.distributedId, positions);
        },
    }
}
</script>