<template>
    <div @click="$emit('click')" :class="divClass + ' ' + type" >
        <div class="outer-boarder" >
            <div class="metalic-boarder">
                <div class="inner-boarder">
                    <div :class="bgClass">
                        <span class="button-content" v-if="!icon">
                            {{ labels[0] }}
                            <span v-if="labels.length > 1"><br>{{labels[1]}}</span>
                        </span>
                        <span class="button-content" v-else>
                            <img src="@/assets/images/icons/camera.svg" alt="" v-if="icon == 'camera'">
                            <img src="@/assets/images/icons/mute.svg" alt="" v-if="icon == 'mute'">
                        </span>   
                        <div class="button-overlay"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['divClass', 'bgClass', 'labels', 'type', 'icon'],
}
</script>