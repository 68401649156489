<template>
    <div id="app" style="height: 100%;">
        <router-view />
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            logRouteTimeout: null,
            logRouteTimeoutTime: 10000,
        }
    },
    mounted() {
        this.logRouteTimeout = setInterval(() => {
            let path = this.$route.path;
            if (path != '/login') {
                this.sendRoute(path);
            }
        }, this.logRouteTimeoutTime);
    },
    beforeDestroy() {
        clearInterval(this.logRouteTimeout);
    },
    methods: {
        async sendRoute(path) {
            try {
                let postData = new FormData();
                postData.append('last_accessed_page', path);
                let response = await axios({
                        method: 'post',
                        url: 'unigens/set_player_navigation_history',
                        data: postData,
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }});
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
    }
</script>