<template>
    <div class="view layout-sim">

        <div class="loading-screen" v-if="loading">
            <h5>Please wait</h5>
            <p>{{ loading_status }}</p>
        </div>


        <div class="canvas" v-if="layout">
            <div @pointerdown="handleObjectMouseDown(object)" @pointerup="handleObjectMouseUp(object)" class="object" v-for="object in layout" :class="object.ObjClass" v-if="object.ViewState[viewState] == true" :style="{
                        'top': object.ObjTop + 'px',
                        'left': object.ObjLeft + 'px',
                        'width': object.ObjWidth + 'px',
                        'height': object.ObjHeight + 'px',
                         }">
                <div class="front" >
                    <!-- {{ object.ObjKey }} <br>
                    ({{ object.ObjClass }}) -->
                    <div class="resolution" v-if="object.ObjType == 'VideoCanvas'">
                        <view-stream @touchStart="touchStart" :touchEnabled="true" addclass="video_1" :code="machine.videoStreams.find(e => e.videoStreamType.code == 'Game').code" class="w-100" />
                        <!-- {{ viewport }} -->
                    </div>
                    <div class="text" v-if="object.ObjClass.includes('BTN_TEXT')">
                        {{ object.ObjTextRow1 }}<br>
                        {{ object.ObjTextRow2 }}
                    </div>
                    <div class="icon" v-if="object.ObjClass.includes('BTN_ICON')">
                        <img :src="getIcon(object.ObjTextRow1)" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="overlays">
            <div class="row loadMoney overlay  justify-content-md-center align-content-center" v-if="showInsert" data-aos="fade-in" style="height: calc(100vh + 50px)">
                <div class="box col-xs-2" data-aos="fade-down">
                    <div class="head">
                        <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                        <div class="text">
                            CURRENT AMOUNT <div class="pull-right">{{ balance | moneyFormat(true) }} RON</div>
                        </div>
                    </div>
                    <div class="row chips justify-content-md-center">
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(0.01)">
                                <div class="image">
                                    <img src="@/assets/images/chips/yellow.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        0.01
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(10)">
                                <div class="image">
                                    <img src="@/assets/images/chips/pink.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        10
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(100)">
                                <div class="image">
                                    <img src="@/assets/images/chips/blue.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        100
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(500)">
                                <div class="image">
                                    <img src="@/assets/images/chips/red.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        500
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row cancel">
                        <div class="col">
                            <button @click="showInsert = false" type="button" class="btn btn-secondary form-control">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "../LayoutSim/LayoutSimView.scss";
</style>
<script>
import axios from 'axios';
import ViewStream from '../Play/components/ViewStream.vue';
import moment from 'moment';
export default {
    data() {
        return {
            viewport: {
                width: null,
                height: null,
                mobile: false,
            },
            resizeBounce: false,
            layout: null,
            viewState: 'ViewState1',
            longClickTime: 1500,
            showInsert: false,
            hand: 'Right',
            balance: 0,
            session: null,
            machine: null,
            loading: false,
            loading_status: '',
            keys_blocked: false,
        }
    },
    components: {
        ViewStream,
    },
    sockets: {
        closedSession: function() {
            this.$router.push('/');
        },
        startLoading: function(status) {
            console.log('startLoading');
            this.loading = true;
            this.loading_status = status;
        },
        stopLoading: function() {
            console.log('stopLoading');   
            this.loading = false;
        },
        session_message: function(message) {
            console.log(message);
            if (message.session_id === this.session.id) {
                if (message.type === 'success') {
                    this.$toasted.success(message.message);
                } else if (message.type === 'error') {
                    this.$toasted.error(message.message);
                }
            }
        },
    },
    beforeDestroy() { 
        this.$socket.emit('pause_play', this.machine.id, this.session.distributed_id, this.$socket.id);
    },
    async mounted() {
        // Load local storage variables
        if(localStorage.getItem('hand')) {
            this.hand = localStorage.getItem('hand');
        } else {
            localStorage.setItem('hand', this.hand);
        }

        await this.getMachine();
        await this.initLayout();
        window.addEventListener("resize", (e) => {
            console.log('resize');
            if (this.resizeBounce) {
                clearTimeout(this.resizeBounce);
            }
            this.resizeBounce = setTimeout(() => {
                this.initLayout();
            }, 500);
        });
    },
    methods: {
        async executeInstruction(object, instruction) {

            // Apply layout
            if(instruction.ApplyStateLayout > 0) {
                this.viewState = 'ViewState' + instruction.ApplyStateLayout;
            }
            // Insert Money
            if(instruction.ExecInsertMoney) {
                this.showInsert = true;
            }
            // Toggle hand
            if(instruction.HandView) {
                this.hand = (this.hand == 'Right') ? 'Left' : 'Right';
                localStorage.setItem('hand', this.hand);
            }

            // Check for RIB command
            if(object.ObjRIBButtonID != -1) {
                this.sendRibCommand(object.ObjRIBButtonID);
            }

            // Redraw layout
            if(instruction.DrawLayout) {
                this.initLayout();
            }

            if(instruction.SessionStatus) {
                this.$router.push('/platform');
            }

            //console.log('EXECUTING ', instruction, ' ON ', object);
        },
        async runObjectEvent(object, event) {
            console.log('running ', event, 'on', object);
            let instruction = object.Instructions.find(e => {
                return (e.ExecEvent == event);
            });
            if(!instruction) {
                console.log('NO INSTRUCTION FOR  ', event , ' ON ', object);
                return;
            }
            this.executeInstruction(object, instruction);
        },
        async handleObjectMouseUp(object) {
            let timestamp = moment();
            // Detect longclick
            let event = 'CLICK';
            if(object.lastMouseDown) {
                let diff = moment().diff(object.lastMouseDown);
                if(diff > this.longClickTime) {
                    event = 'LONGCLICK';
                }
            }
            // Run object EVENT
            this.runObjectEvent(object, event);
        },
        async handleObjectMouseDown(object) {
            let timestamp = moment();
            object.lastMouseDown = timestamp;
        },
        async initLayout() {
            await this.getWindow();
            await this.getLayoutData();
        },
        async getLayoutData() {
            try {
                let orientation = (this.viewport.width - this.viewport.height > 0) ? 'Landscape' : 'Portrait';
                let response = await axios.get(`unigens/get_interface_objects?miSystem=Remote&miMachine=78&miLang=1&miAreaWidth=${this.viewport.width}&miAreaHeight=${this.viewport.height}&miDevice=${this.$route.query.device}&miOrientation=${orientation}&miHand=${this.hand}&fullScreenViewState=false`);
                this.layout = response.data.Result;
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async getWindow() {
            this.viewport.width = window.innerWidth;
            this.viewport.height = window.innerHeight;
            // Implement detect mobile later
            this.viewport.mobile = false;
            console.log('current viewport', this.viewport);
        },
        async getMachine() {
            try {

                let res = await this.$http.get(process.env.VUE_APP_API_SIGNAL + '/api/sessions/' + this.$route.params.id);
                this.session = res.data;

                let response = await axios.get(process.env.VUE_APP_API_SIGNAL + '/api/machines/78');
                this.machine = response.data;

                this.$root.$emit('setHeaderDetails', {
                    manufacturer: this.machine.manufacturer.name,
                    slotNumber: this.machine.number,
                })

            } catch (error) {
                this.$toasted.error("Machine error");
                console.log(error);
            }
        },
        getIcon(name) {
            return require('@/assets/images/icons/mute_on.svg');
        },
        insert(amount) {
            this.loading = true;
            this.$http.post(process.env.VUE_APP_API_SIGNAL + '/api/sessions/addFunds', {
                    session_id: this.$route.params.id,
                    amount: amount,
                    socket_id: this.$socket.id,
                })
                .then(res => {

                    this.loading = false;
                    this.showAddFunds = false;

                    if (res.data.success) {
                        this.$toasted.success('Credit added');
                    } else {
                        this.$toasted.error(res.data.reason);
                    }

                    this.showInsert = false;

                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;

                    this.showInsert = false;
                });
        },
        sendRibCommand(command) {
            var self = this;
            if (this.keys_blocked) {
                return;
            }
            var smib = this.machine.controlBoard;
            this.$socket.emit('send_command', smib, command);
            this.keys_blocked = true;
            setTimeout(function() {
                self.keys_blocked = false;
            }, 300);
        },
        touchStart(positions) {
            var smib = this.machine.controlBoard;
            //let mainStream = this.machine.videoStreams.find(e => e.videoStreamType.code == 'Game');
            this.$socket.emit('touch_start', smib, this.session.distributedId, positions);

        },
    }
}
</script>