<template>
<div class="view profile">
	<div class="title">
		Player Profile Details
	</div>
	<div class="row">
		<div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
			<!-- Group -->
			<div class="group">
				<div class="name">Account Information</div>
				<div class="fields">
					<div class="field">
						<div class="label">
							User Name:
						</div>
						<div class="value">
							{{ player.user.userName }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							Full Name:
						</div>
						<div class="value">
							{{ player.fullName }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							Email:
						</div>
						<div class="value">
							-
						</div> 
					</div>
				</div>
			</div>
			
			<!-- Group -->
			<div class="group">
				<div class="name">Funds Available</div>
				<div class="fields">
					<div class="field">
						<div class="label">
							Deposit:
						</div>
						<div class="value">
							{{ player.account.deposit | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							Cashable:
						</div>
						<div class="value">
							{{ player.account.cashable | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							Bonus:
						</div>
						<div class="value">
							{{ player.account.promo | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							Balance:
						</div>
						<div class="value">
							{{ player.account.balance | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
				</div>
			</div>
			<!-- Group -->
			<div class="group">
				<div class="name">Last Login</div>
				<div class="fields">
					<div class="field">
						<div class="label">
							IP Address:
						</div>
						<div class="value">
							{{ player.user.lastLogin.remoteIpAddress }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							Login Time:
						</div>
						<div class="value">
							{{ player.user.lastLogin.creationTime | moment("YYYY-MM-DD HH:mm:ss") }}
						</div> 
					</div>
				</div>
			</div>
		</div>
		<div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
			

			

			<!-- Group -->
			<div class="group">
				<div class="name">Change Password</div>
				<div class="fields">
					<br>
					<div class="field">
						<label>Current Password</label>
    					<input type="password" v-model="credentials.currentPassword" class="form-control" style="border-color: #f2e3b3 !important;" >
					</div>
					<div class="field" style="margin-top: 16px;">
						<label>New Password</label>
    					<input type="password" v-model="credentials.newPassword" class="form-control" style="border-color: #f2e3b3 !important;" >
					</div>
					<div class="field" style="margin-top: 16px;">
						<label>New Password Confirmation</label>
    					<input type="password"  v-model="credentials.newPasswordConfirmation" class="form-control" style="border-color: #f2e3b3 !important;" >
					</div><br>
					<div class="field">
						<button @click="changePassword" type="button" class="btn btn-primary form-control" style="background-color: #f2e3b3 !important; color: #323448;">UPDATE</button>
					</div>
				</div>
			</div>

			<!-- Group -->
			<div class="group">
				<div class="name">Last Password Change</div>
				<div class="fields" v-if="player.user.lastPasswordChange">
					<div class="field">
						<div class="label">
							IP Address:
						</div>
						<div class="value">
							{{ player.user.lastPasswordChange.remoteIpAddress }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							Login Time:
						</div>
						<div class="value">
							{{ player.user.lastPasswordChange.creationTime | moment("YYYY-MM-DD HH:mm:ss") }}
						</div> 
					</div>
				</div>
				<div class="fields" v-else>
					<div class="field">
					No password change
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<style lang="scss">
@import "ProfileView.scss";
</style>
<script>
import axios from 'axios';
export default {
	props: ['player'],
	data() {
		return {
			credentials: {
				currentPassword: '',
				newPassword: '',
				newPasswordConfirmation: '',
			}
		}
	},
	methods: {
		async changePassword() {
			try {
				let response = await axios.post('players/changePassword', this.credentials);
				this.$toasted.success("Password changed");

				this.credentials = {
					currentPassword: '',
					newPassword: '',
					newPasswordConfirmation: '',
				}

				this.$emit('update');
			} catch (error) {
				if (error.response && error.response.status == 400) {
                    if (error.response.data.errors) {
                        for (let key in error.response.data.errors) {
                            let responseError = error.response.data.errors[key];
                            responseError.forEach(message => {
                                this.$toasted.error(message);
                            })
                        }
                    }
                } else {
                    console.log(error);
                    this.$toasted.error('Server error');
                }
			}
		}
	}
}
</script>